import { Modal, ModalProps } from 'react-bootstrap';

interface DialogProps extends ModalProps {
  title: string;
  setShow: (show: boolean) => void;
  children: JSX.Element | JSX.Element[];
}

export const Dialog = ({
  title,
  setShow,
  children,
  show,
  fullscreen = 'md-down',
  ...rest
}: DialogProps) => {
  return (
    <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)} {...rest}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};
