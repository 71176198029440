import { getUser } from '../../user/selectors';
import { getAuthLoader, getAuthSuccess } from '../selectors';

export const LoginSelector = {
  // loading: getAuthLoader,
  // success: getAuthSuccess,
  data: getUser,
  params: getUser,
  info: getUser,
};
