import classNames from 'classnames';
import { Table } from 'react-bootstrap';
import { Header } from 'store/importazioni/importazione';

interface SimpleTableProps {
  header: Header[];
  rows: any[];
  templateRow?: string;
}

export const SimpleTable = ({ header, rows, templateRow = '5em 29.5rem' }: SimpleTableProps) => {
  return (
    <div
      className="table-responsive"
      style={{
        display: 'grid',
        gridTemplateRows: templateRow,
      }}
    >
      <Table className="mb-0" size="sm">
        <thead
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 2,
            backgroundColor: 'white',
          }}
        >
          <tr>
            {header.map((head, index) => {
              return <th key={`head-${index}`}>{head.value}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => {
            return (
              <tr key={`row-${index}`}>
                {header.map((head, indexKeys) => {
                  return <td key={`cell-${indexKeys}`}>{row[head.key]}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default SimpleTable;
