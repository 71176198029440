import { useState } from 'react';

interface DisclosureProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  handleClick: () => void;
}

export const useDisclosure = (): DisclosureProps => {
  const [show, setShow] = useState(false);
  const onOpen = () => {
    setShow(true);
  };
  const onClose = () => {
    setShow(false);
  };

  const click = () => {
    show ? onClose() : onOpen();
  };

  return {
    isOpen: show,
    onOpen,
    onClose,
    handleClick: click,
  };
};

export default useDisclosure;
