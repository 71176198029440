import { createSlice } from '@reduxjs/toolkit';
import {
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  setLayout,
  changeLayoutType,
  changeLayoutWidth,
  changeMenuPositions,
  toggleSidebarUserInfo,
} from '../actions';
import { Layout } from '../types';

const initialState: Layout = {
  layoutType: 'horizontal',
  layoutWidth: 'fluid',
  leftSideBarType: 'default',
  leftSideBarTheme: 'light',
  topbarTheme: 'light',
  menuPosition: 'fixed',
  showSidebarUserInfo: false,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changeSidebarTheme, (state, { payload }) => {
        state.leftSideBarTheme = payload;
      })
      .addCase(changeSidebarType, (state, { payload }) => {
        state.leftSideBarType = payload;
      })
      .addCase(changeTopbarTheme, (state, { payload }) => {
        state.topbarTheme = payload;
      })
      .addCase(changeLayoutType, (state, { payload }) => {
        state.layoutType = payload;
      })
      .addCase(changeLayoutWidth, (state, { payload }) => {
        state.layoutWidth = payload;
      })
      .addCase(toggleSidebarUserInfo, (state, { payload }) => {
        state.showSidebarUserInfo = payload;
      })
      .addCase(changeMenuPositions, (state, { payload }) => {
        state.menuPosition = payload;
      })
      .addCase(setLayout, (state, { payload }) => {
        state.layoutType = payload.layoutType;
        state.layoutWidth = payload.layoutWidth;
        state.menuPosition = payload.menuPosition;
        state.leftSideBarTheme = payload.leftSideBarTheme;
        state.leftSideBarType = payload.leftSideBarType;
        state.topbarTheme = payload.topbarTheme;
        state.showSidebarUserInfo = payload.showSidebarUserInfo;
      });
  },
});
