import { selectorTypes } from '../../..';
import {
  getAnagrafiche,
  getAnagraficheFailed,
  getAnagraficheInfo,
  getAnagraficheLoader,
  getAnagraficheOption,
  getAnagraficheOptionFailed,
  getAnagraficheOptionInfo,
  getAnagraficheOptionLoader,
  getAnagraficheOptionSuccess,
  getAnagraficheParams,
  getAnagraficheParamsOption,
  getAnagraficheSelectedIds,
  getAnagraficheSuccess,
  getAnagraficaById,
  getAnagraficaOptionById,
  getAnagraficheKeyValues,
  getAnagraficheOptionKeyValues,
} from '../selectors';

export const AnagraficheMapSelector = {
  loading: getAnagraficheLoader,
  success: getAnagraficheSuccess,
  failed: getAnagraficheFailed,
  data: getAnagrafiche,
  info: getAnagraficheInfo,
  params: getAnagraficheParams,
  loadingOption: getAnagraficheOptionLoader,
  successOption: getAnagraficheOptionSuccess,
  failedOption: getAnagraficheOptionFailed,
  dataOption: getAnagraficheOption,
  infoOption: getAnagraficheOptionInfo,
  paramsOption: getAnagraficheParamsOption,
  selectedIds: getAnagraficheSelectedIds,
  selectKeyValues: getAnagraficheKeyValues,
  selectOptionKeyValues: getAnagraficheOptionKeyValues,
  elementById: getAnagraficaById,
  elementOptionById: getAnagraficaOptionById,
};

export default AnagraficheMapSelector;
