import FormInput from 'components/FormInput';
import { Card, Row, Col } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

export const DatiGenerali = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext();
  return (
    <Card className="mb-3">
      <Card.Header>
        <span>Dati generali</span>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={6}>
            <FormInput
              type="text"
              name="nome"
              label={'Nome filtro'}
              placeholder="Clienti Cagliari"
              minHeight="6.25rem"
              register={register}
              errors={errors}
              disabled
            />
          </Col>
          <Col md={3}>
            <FormInput
              type="text"
              name="module"
              label="Sezione"
              placeholder="anagrafica"
              minHeight="6.25rem"
              register={register}
              errors={errors}
              disabled
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DatiGenerali;
