import { SelectorType } from '../../../utils/constants/selectorTypes';
import { getFiltro, getFiltroFailed, getFiltroLoader, getFiltroSuccess } from '../selectors';

export const FiltroMapSelector = {
  loadingElement: getFiltroLoader,
  successElement: getFiltroSuccess,
  failedElement: getFiltroFailed,
  element: getFiltro,
};

export default FiltroMapSelector;
