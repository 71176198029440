import { ActionType, UPDATE_SINGLE_RECORD } from '../../..';
import {
  fetchSchede,
  fetchSchedeOptions,
  resetSchedaParams,
  resetSchedaParamsOption,
  resetSchedaSelectedIds,
  resetSchede,
  resetSchedeOption,
  setSchedaParams,
  setSchedaParamsOption,
  setSchedaSelectedIds,
  selectSchedaAll,
  deleteSchedeMulti,
  updateSingleRecordSchede,
} from '../actions';

export const SchedeMapAction: Record<ActionType | string, any> = {
  fetchList: fetchSchede,
  fetchListOption: fetchSchedeOptions,
  setParams: setSchedaParams,
  setParamsOption: setSchedaParamsOption,
  setSelectedIds: setSchedaSelectedIds,
  resetSelectedIds: resetSchedaSelectedIds,
  resetParams: resetSchedaParams,
  resetParamsOption: resetSchedaParamsOption,
  resetList: resetSchede,
  resetListOption: resetSchedeOption,
  setSelectAll: selectSchedaAll,
  deleteMulti: deleteSchedeMulti,
  updateSingleRecord: updateSingleRecordSchede,
};

export default SchedeMapAction;
