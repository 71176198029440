import { createSlice } from '@reduxjs/toolkit';
import { FAILED, IDLE, PENDING, State, SUCCEEDED } from '../../..';
import { merge } from '../../../../utils';
import { Filtro } from '../../filtro/types/model';
import {
  fetchFiltriOptions,
  fetchFiltri,
  setFiltroParams,
  resetFiltroParams,
  setFiltroParamsOption,
  resetFiltroParamsOption,
  setFiltroSelectedIds,
  resetFiltroSelectedIds,
  deleteFiltriMulti,
  updateSingleRecordFiltri,
} from '../actions';

import { resetFiltri } from '../actions/reset';
import { resetFiltriOption } from '../actions/resetOption';
import { selectFiltroAll } from '../actions/selectAll';

const initialState: State<Filtro> = {
  data: [],
  info: undefined,
  dataOption: [],
  infoOption: undefined,
  selectedIds: [],
  params: undefined,
  paramsOption: undefined,
  error: null,
};

export const filtriSlice = createSlice({
  name: 'filtri',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFiltri.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.data = data?.list;
        state.info = data?.info;
        state.loading = SUCCEEDED;
        state.error = null;
      })
      .addCase(fetchFiltri.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(fetchFiltri.rejected, (state, action) => {
        // const statusCode = payload?.statusCode ?? 500;
        // const error = payload?.error;
        const payload = action?.error;
        const statusCode = payload?.code ?? payload?.name;
        state.error = payload?.message ?? payload?.name ?? statusCode;
        state.loadingOption = FAILED;
      })

      .addCase(selectFiltroAll.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.selectedIds = data;
        state.loading = SUCCEEDED;
        state.error = null;
      })
      .addCase(selectFiltroAll.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(selectFiltroAll.rejected, (state, action) => {
        const payload = action?.error;
        const statusCode = payload?.code ?? payload?.name;
        state.error = payload?.message ?? payload?.name ?? statusCode;
        state.loadingOption = FAILED;
      })

      .addCase(fetchFiltriOptions.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.dataOption = data?.list;
        state.infoOption = data?.info;
        state.loadingOption = SUCCEEDED;
        state.error = null;
      })
      .addCase(fetchFiltriOptions.pending, (state) => {
        state.loadingOption = PENDING;
      })
      .addCase(fetchFiltriOptions.rejected, (state, action) => {
        const payload = action?.error;
        const statusCode = payload?.code ?? payload?.name;
        state.error = payload?.message ?? payload?.name ?? statusCode;
        state.loadingOption = FAILED;
      })
      .addCase(resetFiltri, (state) => {
        state.data = [];
        state.info = undefined;
        state.loading = IDLE;
        state.params = undefined;
        state.selectedIds = [];
      })
      .addCase(resetFiltriOption, (state) => {
        state.dataOption = [];
        state.infoOption = undefined;
        state.loadingOption = IDLE;
        state.paramsOption = undefined;
      })
      .addCase(setFiltroParams, (state, { payload }) => {
        state.params = { ...state.params, ...payload };
      })
      .addCase(resetFiltroParams, (state) => {
        state.params = undefined;
      })
      .addCase(setFiltroParamsOption, (state, { payload }) => {
        state.paramsOption = { ...state.paramsOption, ...payload };
      })
      .addCase(resetFiltroParamsOption, (state) => {
        state.paramsOption = undefined;
      })
      .addCase(setFiltroSelectedIds, (state, { payload }) => {
        const ids = state.selectedIds;
        state.selectedIds = ids.includes(payload)
          ? ids.filter((currentId: string | number) => currentId !== payload)
          : [payload, ...state.selectedIds];
      })
      .addCase(resetFiltroSelectedIds, (state) => {
        state.selectedIds = [];
      })
      .addCase(updateSingleRecordFiltri, (state, { payload }) => {
        if (state.data) {
          state.data = state.data?.map((row) =>
            Number(row.id) === Number(payload.id) ? payload : row
          );
        }
        if (state.dataOption) {
          state.dataOption = state.dataOption?.map((row) =>
            Number(row.id) === Number(payload.id) ? payload : row
          );
        }
      });
  },
});
