import React, { useRef } from 'react';
import useHover from 'hooks/useHover';
export const OptionField = ({ presentation, select, ...props }: { [key: string]: any }) => {
  const ref = useRef(null);
  const isHover = useHover(ref);
  return (
    <div
      ref={ref}
      style={{
        cursor: 'pointer',
        paddingInlineStart: '1rem',
        paddingInlineEnd: '1rem',
        paddingTop: '0.1rem',
        paddingBottom: '0.2rem',
        background: isHover ? '#e3e3e3' : '#fbfbfb',
        fontWeight: isHover ? 'bold' : 'normal',
      }}
      key={presentation.id}
      onClick={() => select(presentation.id)}
      {...props}
    >
      <span>{presentation.description}</span>
    </div>
  );
};

export default OptionField;
