import {
  getReferente,
  getReferenteFailed,
  getReferenteLoader,
  getReferenteSuccess,
} from '../selectors';

export const ReferenteMapSelector = {
  loadingElement: getReferenteLoader,
  successElement: getReferenteSuccess,
  failedElement: getReferenteFailed,
  element: getReferente,
};

export default ReferenteMapSelector;
