import { getTokenCookies } from 'store';

interface Return {
  isAuthenticated: boolean;
  token: string;
  refreshToken: string;
}

const useAuth = (): Return => {
  const { token, refreshToken } = getTokenCookies();
  return {
    token: token || '',
    refreshToken: refreshToken || '',
    isAuthenticated: !!token,
  };
};

export default useAuth;
