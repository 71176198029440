import React from 'react';
import { HOME, HOME_START } from 'utils';
import PageTitle from '../../components/PageTitle';

const Home = () => {
  return (
    <PageTitle
      breadCrumbItems={[
        { label: 'Pages', path: HOME },
        { label: 'Home', path: HOME_START, active: true },
      ]}
      title={'Home'}
    />
  );
};

export default Home;
