import { createSelector } from 'reselect';
import { PENDING, FAILED, SUCCEEDED, RootState } from '../../..';

export const getNazioniState = (state: RootState) => state.nazioni;

export const getNazioniLoader = createSelector(
  getNazioniState,
  (nazioniState) => nazioniState.loading === PENDING
);

export const getNazioniFailed = createSelector(
  getNazioniState,
  (nazioniState) => nazioniState.loading === FAILED
);

export const getNazioniSuccess = createSelector(
  getNazioniState,
  (nazioniState) => nazioniState.loading === SUCCEEDED
);

export const getNazioniError = createSelector(
  getNazioniState,
  (nazioniState) => nazioniState?.error
);

// export const getNazioni = createSelector(getNazioniState, (nazioniState) => nazioniState?.data);
