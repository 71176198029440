import { createSlice } from '@reduxjs/toolkit';
import { fetchNazioni } from '..';
import { IDLE, PENDING, FAILED, SUCCEEDED } from '../../../types';
import { StateNation } from '../types';

const initialState: StateNation = {
  data: [],
  loading: IDLE,
  error: null,
};

export const nazioniSlice = createSlice({
  name: 'nazioni',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNazioni.fulfilled, (state, { payload }) => {
        const statusCode = payload?.statusCode;
        const data = payload?.data;
        state.data = data;
        state.loading = SUCCEEDED;
        state.error = null;
      })
      .addCase(fetchNazioni.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(fetchNazioni.rejected, (state, action) => {
        state.error = action?.error?.code || action?.error?.name || '';
        state.loading = FAILED;
      });
  },
});
