import { KeyValue } from 'store/types';

interface QueryString {
  [key: string]: string | string[] | number[] | number | boolean | KeyValue | null | undefined;
}

export const queryString = (params: QueryString = {}): string => {
  // get array of key value pairs ([[k1, v1], [k2, v2]])
  const qs = Object.entries(params)
    // filter pairs with undefined value
    .filter((pair): boolean => pair[1] !== undefined)
    // encode keys and values, remove the value if it is null, but leave the key
    .map((pair): string => {
      if (Array.isArray(pair[1])) {
        return (
          pair[1]
            // @ts-ignore
            .filter((i): boolean => i !== null)
            .map((el: string | number | boolean | KeyValue) => {
              if (typeof el !== 'object') {
                return `${pair[0]}=`.concat(encodeURIComponent(el));
              }
              return `${pair[0]}=`.concat(encodeURIComponent(el.id));
            })
            .join('&')
        );
      }
      return (
        pair
          .filter((i): boolean => i !== null)
          // @ts-ignore
          .map((el: string | number | boolean | KeyValue) => {
            if (typeof el !== 'object') {
              return encodeURIComponent(el);
            }
            return encodeURIComponent(el.id);
          })
          .join('=')
      );
    })
    .join('&');
  return qs && `?${qs}`;
};
