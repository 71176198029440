import { IMPORTAZIONE } from './../../../../utils/constants/modules';
import { createSlice } from '@reduxjs/toolkit';
import { importFile, resetImportazione } from '../actions';
import { FAILED, IDLE, PENDING, SUCCEEDED } from '../../../types';
import { StateImportazione } from '../types/state';
import { confirmImport } from '../actions/confirm';

const initialState: StateImportazione = {
  data: null,
  loading: IDLE,
  error: null,
  header: [],
  rows: [],
  added: [],
  updated: [],
  notImported: [],
};

export const importazioneSlice = createSlice({
  name: IMPORTAZIONE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(importFile.fulfilled, (state, { payload }) => {
        state.data = payload?.data?.importazione;
        state.header = payload?.data?.header ?? [];
        state.rows = payload?.data?.rows ?? [];
        state.loading = SUCCEEDED;
      })
      .addCase(importFile.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(importFile.rejected, (state, action) => {
        state.error = action?.error?.code || action?.error?.name || '';
        state.loading = FAILED;
      })
      .addCase(resetImportazione, (state) => {
        state.data = null;
        state.header = [];
        state.rows = [];
        state.loading = IDLE;
        state.error = null;
      })
      .addCase(confirmImport.fulfilled, (state, { payload }) => {
        state.data = payload?.data?.importazione;
        state.rows = [];
        state.added = payload?.data?.added ?? [];
        state.updated = payload?.data?.updated ?? [];
        state.notImported = payload?.data?.notImported ?? [];
        state.loading = SUCCEEDED;
      })
      .addCase(confirmImport.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(confirmImport.rejected, (state, action) => {
        state.error = action?.error?.code || action?.error?.name || '';
        state.rows = [];
        state.loading = FAILED;
      });
  },
});
