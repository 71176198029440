import jwtDecode from 'jwt-decode';

export const isTokenExpired = async (token: string | null) => {
  if (token) {
    try {
      const decoded: any = await jwtDecode(token);

      if (decoded) {
        const { exp } = decoded;
        const currentDate = new Date();
        const threshold = currentDate.setMinutes(currentDate.getMinutes());
        return exp < threshold / 1000;
      }
    } catch (error: any) {
      return true;
    }
  }
  return true;
};
