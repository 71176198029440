import { BASE, URL_REFRESH, URL_USER, URL_V1 } from '../../utils/constants';
import { addTokenCookies, getTokenCookies, removeTokenCookies, isTokenExpired } from '..';
import axios from 'axios';

const fetchRefreshToken = async (refreshToken: string) => {
  const { token } = getTokenCookies();
  axios.defaults.baseURL = BASE;
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json';

  try {
    const response = await axios.get(`${URL_USER}${URL_REFRESH}/${refreshToken}`);
    if (response.status === 200) {
      const { access_token, refresh } = response?.data;
      return { token: access_token, refresh };
    }
    return null;
  } catch (error: any) {
    return null;
  }
};

export default async function checkTokenValidity() {
  const { token, refreshToken } = getTokenCookies();
  const isExpired = await isTokenExpired(token);
  if (!isExpired) {
    return token;
  }
  if (token && refreshToken) {
    try {
      const result = await fetchRefreshToken(refreshToken);
      const { token, refresh } = result || {};
      if (token && refresh) {
        addTokenCookies({ token, refreshToken: refresh });
        return token;
      }

      removeTokenCookies();
      window.location.replace('/auth/login');
      return null;
    } catch (error: any) {
      removeTokenCookies();
      window.location.replace('/auth/login');
      return null;
    }
  }
  //window.location.replace('/login');
  removeTokenCookies();
  return null;
}
