import { ReactElement } from 'react';

interface TextProps extends React.CSSProperties {
  text: string;
}
export const Text = ({ text, ...style }: TextProps) => {
  return <p style={style}>{text}</p>;
};

export default Text;
