import { ActionType, UPDATE_SINGLE_RECORD } from '../../..';
import {
  fetchAnagrafiche,
  fetchAnagraficheOptions,
  resetAnagraficaParams,
  resetAnagraficaParamsOption,
  resetAnagraficaSelectedIds,
  resetAnagrafiche,
  resetAnagraficheOption,
  setAnagraficaParams,
  setAnagraficaParamsOption,
  setAnagraficaSelectedIds,
  selectAnagraficaAll,
  deleteAnagraficheMulti,
  updateSingleRecordAnagrafiche,
} from '../actions';

export const AnagraficheMapAction: Record<ActionType | string, any> = {
  fetchList: fetchAnagrafiche,
  fetchListOption: fetchAnagraficheOptions,
  setParams: setAnagraficaParams,
  setParamsOption: setAnagraficaParamsOption,
  setSelectedIds: setAnagraficaSelectedIds,
  resetSelectedIds: resetAnagraficaSelectedIds,
  resetParams: resetAnagraficaParams,
  resetParamsOption: resetAnagraficaParamsOption,
  resetList: resetAnagrafiche,
  resetListOption: resetAnagraficheOption,
  setSelectAll: selectAnagraficaAll,
  deleteMulti: deleteAnagraficheMulti,
  updateSingleRecord: updateSingleRecordAnagrafiche,
};
