import { createSelector } from 'reselect';
import { Filtro } from 'store/filtri/filtro';
import { IDLE, PENDING, FAILED, SUCCEEDED, RootState, Info } from '../../..';

export const getFiltriState = (state: RootState) => state.filtri;

export const getFiltriLoader = createSelector(
  getFiltriState,
  (filtriState) => filtriState.loading === PENDING
);

export const getFiltriFailed = createSelector(
  getFiltriState,
  (filtriState) => filtriState.loading === FAILED
);

export const getFiltriSuccess = createSelector(
  getFiltriState,
  (filtriState) => filtriState.loading === SUCCEEDED
);

export const getFiltriOptionLoader = createSelector(
  getFiltriState,
  (filtriState) => filtriState.loadingOption === PENDING
);

export const getFiltriOptionFailed = createSelector(
  getFiltriState,
  (filtriState) => filtriState.loadingOption === FAILED
);

export const getFiltriOptionSuccess = createSelector(
  getFiltriState,
  (filtriState) => filtriState.loadingOption === SUCCEEDED
);

export const getFiltriEnumLoader = createSelector(
  getFiltriState,
  (filtriState) => filtriState.loadingEnum === PENDING
);

export const getFiltriEnumFailed = createSelector(
  getFiltriState,
  (filtriState) => filtriState.loadingEnum === FAILED
);

export const getFiltriEnumSuccess = createSelector(
  getFiltriState,
  (filtriState) => filtriState.loadingEnum === SUCCEEDED
);

export const getFiltriError = createSelector(getFiltriState, (filtriState) => filtriState?.error);

export const getFiltri = createSelector(
  getFiltriState,
  (filtriState): Filtro[] => filtriState?.data
);

export const getFiltriInfo = createSelector(
  getFiltriState,
  (filtriState): Info => filtriState?.info ?? { pages: 0, currentPage: 0, total: 0 }
);

export const getFiltriOption = createSelector(
  getFiltriState,
  (filtriState) => filtriState?.dataOption
);

export const getFiltriOptionInfo = createSelector(
  getFiltriState,
  (filtriState) => filtriState?.infoOption
);

export const getFiltriTipologie = createSelector(
  getFiltriState,
  (filtriState) => filtriState?.tipologie
);

export const getFiltriKeyValues = createSelector(getFiltri, (filtri) => filtri?.map((filtro: Filtro) => ({ id: filtro.id, descrizione: filtro.nome })) ?? []);
export const getFiltriOptionKeyValues = createSelector(getFiltriOption, (filtri) => filtri?.map((filtro: Filtro) => ({ id: filtro.id, descrizione: filtro.nome })) ?? []);

export const getFiltroById = (id: string | number) =>
  createSelector(getFiltri, (filtri) => filtri?.find((filtroElement) => filtroElement.id === id));

export const getFiltroOptionById = (id: string | number) =>
  createSelector(getFiltriOption, (filtri) =>
    filtri?.find((filtroElement) => filtroElement.id === id)
  );

export const getFiltroTipologia = (tipologia: string | number) =>
  createSelector(getFiltriTipologie, (tipologie) => {
    const tipologiaElement = tipologie
      ? tipologie.find((element: Record<string, any>) => element.id === tipologia)
      : null;
    return tipologiaElement;
  });

export const getFiltriParams = createSelector(getFiltriState, (filtriState) => filtriState?.params);

export const getFiltriParamsOption = createSelector(
  getFiltriState,
  (filtriState) => filtriState?.paramsOption
);

export const getFiltriSelectedIds = createSelector(
  getFiltriState,
  (filtriState) => filtriState?.selectedIds
);
