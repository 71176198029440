import { Filtro } from '../types';
import { createSlice } from '@reduxjs/toolkit';
import { createFiltro, deleteFiltro, fetchFiltro, resetFiltro } from '../actions';
import { FAILED, IDLE, PENDING, StateSingular, SUCCEEDED } from '../../../types';

const initialState: StateSingular<Filtro> = {
  data: null,
  loading: IDLE,
  error: null,
};

export const filtroSlice = createSlice({
  name: 'filtro',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFiltro.fulfilled, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = SUCCEEDED;
      })
      .addCase(fetchFiltro.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(fetchFiltro.rejected, (state, action) => {
        state.error = action?.error?.code || action?.error?.name || '';
        state.loading = FAILED;
      })
      .addCase(createFiltro.fulfilled, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = SUCCEEDED;
      })
      .addCase(createFiltro.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(createFiltro.rejected, (state, action) => {
        state.error = action?.error?.code || action?.error?.name || '';
        state.loading = FAILED;
      })
      .addCase(resetFiltro, (state) => {
        state.data = null;
        state.loading = IDLE;
        state.error = null;
      })
      .addCase(deleteFiltro.fulfilled, (state) => {
        state.data = null;
        state.loading = SUCCEEDED;
      })
      .addCase(deleteFiltro.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(deleteFiltro.rejected, (state, action) => {
        state.error = action?.error?.code || action?.error?.name || '';
        state.loading = FAILED;
      });
  },
});
