import { SelectorType } from '../../../utils/constants/selectorTypes';
import { getModulo, getModuloFailed, getModuloLoader, getModuloSuccess } from '../selectors';

export const ModuloMapSelector = {
  loadingElement: getModuloLoader,
  successElement: getModuloSuccess,
  failedElement: getModuloFailed,
  element: getModulo,
};

export default ModuloMapSelector;
