import { Box } from 'components';
import { ConfrimModal } from 'components/ConfirmModal';
import React from 'react';
import { useState } from 'react';

interface ConfirmDelegateProps {
  title: string;
  clickElement: React.ReactElement;
  children: React.ReactElement | React.ReactElement[];
  onConfirm: (params?: any) => void;
}

export const ConfirmDelegate = ({
  title,
  clickElement,
  children,
  onConfirm,
}: ConfirmDelegateProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const element = React.cloneElement(clickElement, { onClick: () => setOpen(true) });
  const confirmAndClose = (params?: any) => {
    onConfirm(params);
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Box>{element}</Box>
      <ConfrimModal
        show={open}
        setShow={(show: boolean) => setOpen(show)}
        title={title}
        onConfirm={confirmAndClose}
        children={children}
        maxWidth="md"
      />
    </React.Fragment>
  );
};

export default ConfirmDelegate;
