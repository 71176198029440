import { createSelector } from 'reselect';
import { FAILED, PENDING, RootState, SUCCEEDED } from '../../..';

export const getModuloState = (state: RootState) => state.modulo;

export const getModuloLoader = createSelector(
  getModuloState,
  (moduloState) => moduloState.loading === PENDING
);

export const getModuloFailed = createSelector(
  getModuloState,
  (moduloState) => moduloState.loading === FAILED
);

export const getModuloSuccess = createSelector(
  getModuloState,
  (moduloState) => moduloState.loading === SUCCEEDED
);

export const getModuloError = createSelector(getModuloState, (moduloState) => moduloState?.error);

export const getModulo = createSelector(getModuloState, (moduloState) => moduloState?.data);
