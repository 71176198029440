export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const FISCAL_CODE_REGEX =
  /^(?:(?:[B-DF-HJ-NP-TV-Z]|[AEIOU])[AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[1256LMRS][\dLMNP-V])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i;
export const INTERNATIONAL_NAME_REGEX =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/i;
export const STARTS_WITH_N_LETTERS_REGEX = /^[A-Za-z]/;
export const ALPHANUMERIC_CHARACTERS_REGEX = /^[a-zA-Z0-9]+$/;
export const LETTERS_REGEX = /^[A-Za-z ,.'èéòàùì]*$/;
export const ITALIAN_IBAN_REGEX = /^IT[0-9]{2}[a-zA-Z][0-9]{5}[0-9]{5}[a-zA-Z0-9]{12}$/;
export const INTERNATIONAL_IBAN_REGEX =
  /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/i;
export const BIC_SWIFT_REGEX = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/i;
export const ONLY_LETTERS_AND_NUMBERS = /^[\w\s\dòàèùì']+$/;
export const NUMBERS_ONLY = /^[0-9]*$/;
export const NUMBERS_FLOAT = /^\d*\.?\d*$/;
export const NUMBERS_FLOAT_COMMA = /^\d*,?\d*$/;
export const NUMBERS_FLOAT_DOT_OR_COMMA = /^\d*[,.]?\d+$/;
export const NUMBERS_FLOAT_DOT_OR_COMMA_OR_PLUS = /^\d*[,.+]?\d*$/;
export const ONLY_FLOAT_DOT_OR_COMMA = /^\d*[,.]?\d*$/;
export const CAP = /^\d{5}$/;
export const PARTITA_IVA_REGEX = /^\d{11}$/; //TODO Controlla solamente la lunghezza. Da verificare se adottare una utility che effettua i check corretti
export const CODICE_FISCALE_REGEX =
  /^\d{11}$|^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i; // /^\d{11}$|^\w{16}$/; //TODO Controlla solamente la lunghezza. Da verificare se adottare una utility che effettua i check corretti
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
export const CURRENCY_WITH_DOT_AND_COMA = /^(?!0\.00)\d{1,3}(.\d{3})*(,\d+)?$/;
export const NUMBERS_FLOAT_DOT = /^\d*[.]?\d*$/;
export const NUMBERS_FLOAT_COMA = /^\d*[,]?\d*$/;
export const PHONE_NUMBER = /^[\+]?[(]?[0-9]{2,3}[)]?[-\s\.]?([0-9]{2,4}[-\s\.])*[0-9]+$/;

interface RegexLengthProps {
  value: string | undefined | null;
  length: number;
}

export const checkLengthByRegex = ({ value, length }: RegexLengthProps): boolean => {
  const REGEX = new RegExp(`^.{0,${length}}$`);
  return value ? REGEX.test(value) : true;
};

export const checkMinLength = ({ value, length }: RegexLengthProps): boolean => {
  const REGEX = new RegExp(`^.{${length},}$`);
  return value ? REGEX.test(value) : true;
};

export const checkExactLength = ({ value, length }: RegexLengthProps): boolean => {
  const REGEX = new RegExp(`^.{${length},${length}}$`);
  return value ? REGEX.test(value) : true;
};

export const isNumericByRegex = (value: string | undefined | null): boolean => {
  const REGEX = new RegExp(NUMBERS_ONLY);
  return value ? REGEX.test(value) : true;
};

export const isNumericGreaterThanZero = (value: string | undefined | null): boolean => {
  const REGEX = new RegExp(NUMBERS_ONLY);
  if (!value || !REGEX.test(value)) {
    return false;
  }
  return Number(value) > 0;
};

export const validateLengthMessage = (value: string, maxLength: number): Record<string, any> => {
  return {
    value,
    interpolation: {
      numero_caratteri: maxLength,
    },
  };
};

function checksumPiva(piva: string): boolean {
  let checksum = 0;
  for (let index = 0; index < 11; index++) {
    let valueCheck = piva.charCodeAt(index) - '0'.charCodeAt(0);
    if ((index & 1) === 1) {
      valueCheck *= 2;
      if (valueCheck > 9) valueCheck -= 9;
    }
    checksum += valueCheck;
  }
  return checksum % 10 === 0;
}

function checksumCodiceFiscale(codiceFiscale: string): boolean {
  let checksum = 0;
  const checkMap = 'BAFHJNPRTVCESULDGIMOQKWZYX';
  for (let index = 0; index < 15; index++) {
    const char = codiceFiscale[index];
    let indexCheck = 0;
    if ('0' <= char && char <= '9') indexCheck = char.charCodeAt(0) - '0'.charCodeAt(0);
    else indexCheck = char.charCodeAt(0) - 'A'.charCodeAt(0);
    if ((index & 1) === 0) indexCheck = checkMap.charCodeAt(indexCheck) - 'A'.charCodeAt(0);
    checksum += indexCheck;
  }
  return (checksum % 26) + 'A'.charCodeAt(0) === codiceFiscale.charCodeAt(15);
}

export const validatePiva = (piva?: string | null): boolean => {
  if (!piva) return true;
  if (!PARTITA_IVA_REGEX.test(piva)) return false;
  return checksumPiva(piva);
};

export const validateCodiceFiscale = (codiceFiscale?: string | null): boolean => {
  if (!codiceFiscale) return true;
  if (PARTITA_IVA_REGEX.test(codiceFiscale)) return checksumPiva(codiceFiscale);
  if (!CODICE_FISCALE_REGEX.test(codiceFiscale)) return false;
  return checksumCodiceFiscale(codiceFiscale);
};
