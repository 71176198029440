import {
  getAnagraficheEnumFailed,
  getAnagraficheEnumLoader,
  getAnagraficheEnumSuccess,
  getAnagraficheTipologie,
  getAnagraficaTipologia,
  getCategorieAnagrafica,
  getCategoriaAnagrafica,
} from '../selectors';
import { SelectorType, selectorTypes } from '../../..';

export const AnagraficheTipolpgieEnumSelector:Record<SelectorType | string, any> = {
  loading: getAnagraficheEnumLoader,
  success: getAnagraficheEnumSuccess,
  failed: getAnagraficheEnumFailed,
  data: getAnagraficheTipologie,
  elementById: getAnagraficaTipologia,
};

export const AnagraficheCategorieEnumSelector = {
  loading: getAnagraficheEnumLoader,
  success: getAnagraficheEnumSuccess,
  failed: getAnagraficheEnumFailed,
  data: getCategorieAnagrafica,
  elementById: getCategoriaAnagrafica,
};
