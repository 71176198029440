import { createSelector } from 'reselect';
import { Scheda } from 'store/schede/scheda';
import { IDLE, PENDING, FAILED, SUCCEEDED, RootState, Info } from '../../..';

export const getSchedeState = (state: RootState) => state.schede;

export const getSchedeLoader = createSelector(
  getSchedeState,
  (schedeState) => schedeState.loading === PENDING
);

export const getSchedeFailed = createSelector(
  getSchedeState,
  (schedeState) => schedeState.loading === FAILED
);

export const getSchedeSuccess = createSelector(
  getSchedeState,
  (schedeState) => schedeState.loading === SUCCEEDED
);

export const getSchedeOptionLoader = createSelector(
  getSchedeState,
  (schedeState) => schedeState.loadingOption === PENDING
);

export const getSchedeOptionFailed = createSelector(
  getSchedeState,
  (schedeState) => schedeState.loadingOption === FAILED
);

export const getSchedeOptionSuccess = createSelector(
  getSchedeState,
  (schedeState) => schedeState.loadingOption === SUCCEEDED
);

export const getSchedeEnumLoader = createSelector(
  getSchedeState,
  (schedeState) => schedeState.loadingEnum === PENDING
);

export const getSchedeEnumFailed = createSelector(
  getSchedeState,
  (schedeState) => schedeState.loadingEnum === FAILED
);

export const getSchedeEnumSuccess = createSelector(
  getSchedeState,
  (schedeState) => schedeState.loadingEnum === SUCCEEDED
);

export const getSchedeError = createSelector(getSchedeState, (schedeState) => schedeState?.error);

export const getSchede = createSelector(
  getSchedeState,
  (schedeState): Scheda[] => schedeState?.data
);

export const getSchedeInfo = createSelector(
  getSchedeState,
  (schedeState): Info => schedeState?.info ?? { pages: 0, currentPage: 0, total: 0 }
);

export const getSchedeOption = createSelector(
  getSchedeState,
  (schedeState) => schedeState?.dataOption
);

export const getSchedeKeyValues = createSelector(getSchede, (schede) => schede?.map((scheda: Scheda) => ({ id: scheda.id, descrizione: scheda.nome })) ?? []);
export const getSchedeOptionKeyValues = createSelector(getSchedeOption, (schede) => schede?.map((scheda: Scheda) => ({ id: scheda.id, descrizione: scheda.nome })) ?? []);

export const getSchedeOptionInfo = createSelector(
  getSchedeState,
  (schedeState) => schedeState?.infoOption
);

export const getSchedaById = (id: string | number) =>
  createSelector(getSchede, (schede) => schede?.find((schedaElement) => schedaElement.id === id));

export const getSchedaOptionById = (id: string | number) =>
  createSelector(getSchedeOption, (schede) =>
    schede?.find((schedaElement) => schedaElement.id === id)
  );

export const getSchedeParams = createSelector(getSchedeState, (schedeState) => schedeState?.params);

export const getSchedeParamsOption = createSelector(
  getSchedeState,
  (schedeState) => schedeState?.paramsOption
);

export const getSchedeSelectedIds = createSelector(
  getSchedeState,
  (schedeState) => schedeState?.selectedIds
);
