import { createSelector } from 'reselect';
import { FAILED, PENDING, RootState, SUCCEEDED } from '../../..';

export const getFiltroState = (state: RootState) => state.filtro;

export const getFiltroLoader = createSelector(
  getFiltroState,
  (filtroState) => filtroState.loading === PENDING
);

export const getFiltroFailed = createSelector(
  getFiltroState,
  (filtroState) => filtroState.loading === FAILED
);

export const getFiltroSuccess = createSelector(
  getFiltroState,
  (filtroState) => filtroState.loading === SUCCEEDED
);

export const getFiltroError = createSelector(getFiltroState, (filtroState) => filtroState?.error);

export const getFiltro = createSelector(getFiltroState, (filtroState) => filtroState?.data);
export const getFiltroChiavi = () =>
  createSelector(getFiltro, (filtro) => filtro?.filtro_chiavi ?? []);
