import { selectorTypes } from '../../..';
import {
  getModuloChiavi,
  getModuloChiaviFailed,
  getModuloChiaviInfo,
  getModuloChiaviLoader,
  getModuloChiaviOption,
  getModuloChiaviOptionFailed,
  getModuloChiaviOptionInfo,
  getModuloChiaviOptionLoader,
  getModuloChiaviOptionSuccess,
  getModuloChiaviParams,
  getModuloChiaviParamsOption,
  getModuloChiaviSelectedIds,
  getModuloChiaviSuccess,
  getModuloChiaveById,
  getModuloChiaveOptionById,
} from '../selectors';

export const ModuloChiaviMapSelector = {
  loading: getModuloChiaviLoader,
  success: getModuloChiaviSuccess,
  failed: getModuloChiaviFailed,
  data: getModuloChiavi,
  info: getModuloChiaviInfo,
  params: getModuloChiaviParams,
  loadingOption: getModuloChiaviOptionLoader,
  successOption: getModuloChiaviOptionSuccess,
  failedOption: getModuloChiaviOptionFailed,
  dataOption: getModuloChiaviOption,
  infoOption: getModuloChiaviOptionInfo,
  paramsOption: getModuloChiaviParamsOption,
  selectedIds: getModuloChiaviSelectedIds,
  elementById: getModuloChiaveById,
  elementOptionById: getModuloChiaveOptionById,
};

export default ModuloChiaviMapSelector;
