import Cookies from 'js-cookie';
import { APP_SIGN, TOKEN, REFRESH_TOKEN } from '../constants';

export function removeTokenCookies() {
  Cookies.remove(`${APP_SIGN}-${TOKEN}`);
  Cookies.remove(`${APP_SIGN}-${REFRESH_TOKEN}`);
}

export function addTokenCookies({ token, refreshToken }: { token: string; refreshToken: string }) {
  Cookies.set(`${APP_SIGN}-${TOKEN}`, token);
  Cookies.set(`${APP_SIGN}-${REFRESH_TOKEN}`, refreshToken);
}

export function getTokenCookies(): {
  token: string | null;
  refreshToken: string | null;
} {
  const token = Cookies.get(`${APP_SIGN}-${TOKEN}`) || null;
  const refreshToken = Cookies.get(`${APP_SIGN}-${REFRESH_TOKEN}`) || null;
  return {
    token,
    refreshToken,
  };
}
