import * as Yup from 'yup';

const filtroSchema = Yup.object().shape({
  nome: Yup.string()
    .required('campo obbligatorio')
    .max(255, 'Caratteri consentiti max 255')
    .nullable(true),
});

export default filtroSchema;
