import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';
import { GridFilterModel, GridRowId, useGridApiRef } from '@mui/x-data-grid-pro';
import {
  RESETLIST,
  RESETSELECTEDIDS,
  RESET_ALL_OPTION,
  SETPARAMS,
  TabsId,
  getAction,
  useAppDispatch,
} from 'store';
import { useDataModal } from './hook';
import GenericGrid from 'components/GenericGrid';
import { Flex } from 'components';
import SearchIcon from '@mui/icons-material/Search';

interface DataModalProps<T> {
  tabId: TabsId;
  parentId?: number;
  button: React.ReactElement;
  onSubmit: () => void;
  title: string;
  additionalParams?: Record<string, any>;
}

const DataModal: React.FC<DataModalProps<any>> = ({
  button,
  parentId,
  tabId,
  onSubmit,
  title,
  additionalParams,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const onClose = () => setOpen(false);

  const [search, setSearch] = React.useState('');

  const [isNew, setIsNew] = React.useState<boolean>(false);
  const [dataRows, setDataRows] = React.useState<any[]>([]);
  const {
    columnSettings: columns,
    handleSearchChange,
    getData,
  } = useDataModal({
    open,
    tabId,
    initialParams: additionalParams,
    parentId,
    dataRows,
    setSearch,
    setDataRows,
    setIsNew,
  });

  const element = React.cloneElement(button, {
    onClick: () => {
      const actionResetParams = getAction(tabId, RESETLIST, true);
      actionResetParams && dispatch(actionResetParams());
      const actionSetParams = getAction(tabId, SETPARAMS, true);
      dispatch(actionSetParams({ parentId, skip: 0, limit: 0, ...(additionalParams ?? {}) }));
      getData();
      setOpen(true);
    },
  });

  const dispatch = useAppDispatch();

  const handleCancel = () => {
    onClose();
    const action = getAction(tabId, RESETLIST, true);
    action && dispatch(action());

    const actionResetSelectedIds = getAction(tabId, RESETSELECTEDIDS, true);
    actionResetSelectedIds && dispatch(actionResetSelectedIds());
  };

  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <React.Fragment>
      {element}
      <Dialog open={open} onClose={handleCancel} maxWidth="lg">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Flex justifyContent="end" paddingBottom="1rem">
            <TextField
              variant="outlined"
              size="small"
              placeholder="Ricerca"
              value={search}
              onChange={handleSearchChange}
              InputProps={{ startAdornment: <SearchIcon /> }}
            />
          </Flex>
          <div style={{ overflowX: 'auto' }}>
            <GenericGrid columns={columns} data={dataRows} tabId={tabId} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Annulla</Button>
          <Button onClick={handleSubmit}>Conferma</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DataModal;
