import { SelectorType } from '../../../utils/constants/selectorTypes';
import {
  getAnagrafica,
  getAnagraficaFailed,
  getAnagraficaLoader,
  getAnagraficaSuccess,
} from '../selectors';

export const AnagraficaMapSelector = {
  loadingElement: getAnagraficaLoader,
  successElement: getAnagraficaSuccess,
  failedElement: getAnagraficaFailed,
  element: getAnagrafica,
};

export default AnagraficaMapSelector;
