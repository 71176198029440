import { PHONE_NUMBER } from 'utils/constants/validations';
import * as Yup from 'yup';

const referenteSchema = Yup.object().shape({
  nome: Yup.string().nullable(true).required('campo obbligatorio'),
  cognome: Yup.string().nullable(true).required('campo obbligatorio'),
  email: Yup.string().email('inserire una mail corretta').nullable(true),
  cellulare: Yup.string()
    .nullable(true)
    .test('cellulare', 'Numero cellulare non valido', (value) => {
      return Promise.resolve(value ? PHONE_NUMBER.test(value) : true);
    }),
  telefono: Yup.string()
    .nullable(true)
    .test('telefono', 'Numero telefono fisso non valido', (value) => {
      return Promise.resolve(value ? PHONE_NUMBER.test(value) : true);
    }),
});

export default referenteSchema;
