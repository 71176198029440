export interface Anagrafica {
  id?: number;
  ragione_sociale: string | null;
  piva: string | null;
  codice_fiscale: string | null;
  tipologia: string | null;
  gruppo_anagrafico: string | null;
  categoria: string | null;
  indirizzo: string | null;
  cap: string | null;
  localita: string | null;
  provincia: string | null;
  provincia_id: number | null;
  regione: string | null;
  regione_id: number | null;
  nazione: string | null;
  nazione_id: string | null;
  civico: string | null;
  email: string | null;
  fax: string | null;
  pec: string | null;
  codice_destinatario: string | null;
  web: string | null;
  nota: string | null;
  nome_banca: string | null;
  iban: string | null;
  codice: string | null;
  referente: string | null;
  telefono: string | null;
  cellulare: string | null;
  pagamento: boolean;
  note_cedolini: string | null;
  note_f24: string | null;
  canone_fisso?: number;
  quota_cedolino?: number;
  disabilitato: boolean;
  padre_id?: number;
  padre_ragione_sociale?: string;
  created?: Date;
  updated?: Date;
}

export class AnagraficaClass implements Anagrafica {
  id = 0;
  ragione_sociale = null;
  piva = null;
  codice_fiscale = null;
  indirizzo = null;
  civico = null;
  cap = null;
  telefono = null;
  cellulare = null;
  localita = null;
  provincia = null;
  provincia_id = null;
  regione = null;
  regione_id = null;
  nazione = null;
  nazione_id = null;
  tipologia = null;
  gruppo_anagrafico = null;
  categoria = null;
  fax = null;
  email = null;
  pec = null;
  codice_destinatario = null;
  web = null;
  nota = null;
  nome_banca = null;
  iban = null;
  codice = null;
  referente = null;
  canone_fisso = 0;
  quota_cedolino = 0;
  disabilitato = false;
  pagamento = false;
  note_cedolini = null;
  note_f24 = null;
  padre_id = undefined;
  padre_ragione_sociale = undefined;
  created = undefined;
  updated = undefined;
}
