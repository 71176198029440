import { createSlice } from '@reduxjs/toolkit';
import { FAILED, IDLE, PENDING, State, SUCCEEDED } from '../../..';
import { merge } from '../../../../utils';
import { Scheda } from '../../scheda/types/model';
import {
  fetchSchedeOptions,
  fetchSchede,
  setSchedaParams,
  resetSchedaParams,
  setSchedaParamsOption,
  resetSchedaParamsOption,
  setSchedaSelectedIds,
  resetSchedaSelectedIds,
  deleteSchedeMulti,
  updateSingleRecordSchede,
} from '../actions';

import { resetSchede } from '../actions/reset';
import { resetSchedeOption } from '../actions/resetOption';
import { selectSchedaAll } from '../actions/selectAll';

const initialState: State<Scheda> = {
  data: [],
  info: undefined,
  dataOption: [],
  infoOption: undefined,
  selectedIds: [],
  params: undefined,
  paramsOption: undefined,
  error: null,
};

export const schedeSlice = createSlice({
  name: 'schede',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSchede.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.data = data?.list;
        state.info = data?.info;
        state.loading = SUCCEEDED;
        state.error = null;
      })
      .addCase(fetchSchede.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(fetchSchede.rejected, (state, action) => {
        // const statusCode = payload?.statusCode ?? 500;
        // const error = payload?.error;
        const payload = action?.error;
        const statusCode = payload?.code ?? payload?.name;
        state.error = payload?.message ?? payload?.name ?? statusCode;
        state.loadingOption = FAILED;
      })

      .addCase(selectSchedaAll.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.selectedIds = data;
        state.loading = SUCCEEDED;
        state.error = null;
      })
      .addCase(selectSchedaAll.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(selectSchedaAll.rejected, (state, action) => {
        const payload = action?.error;
        const statusCode = payload?.code ?? payload?.name;
        state.error = payload?.message ?? payload?.name ?? statusCode;
        state.loadingOption = FAILED;
      })

      .addCase(fetchSchedeOptions.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.dataOption = data?.list;
        state.infoOption = data?.info;
        state.loadingOption = SUCCEEDED;
        state.error = null;
      })
      .addCase(fetchSchedeOptions.pending, (state) => {
        state.loadingOption = PENDING;
      })
      .addCase(fetchSchedeOptions.rejected, (state, action) => {
        const payload = action?.error;
        const statusCode = payload?.code ?? payload?.name;
        state.error = payload?.message ?? payload?.name ?? statusCode;
        state.loadingOption = FAILED;
      })
      .addCase(resetSchede, (state) => {
        state.data = [];
        state.info = undefined;
        state.loading = IDLE;
        state.params = undefined;
        state.selectedIds = [];
      })
      .addCase(resetSchedeOption, (state) => {
        state.dataOption = [];
        state.infoOption = undefined;
        state.loadingOption = IDLE;
        state.paramsOption = undefined;
      })
      .addCase(setSchedaParams, (state, { payload }) => {
        state.params = { ...state.params, ...payload };
      })
      .addCase(resetSchedaParams, (state) => {
        state.params = undefined;
      })
      .addCase(setSchedaParamsOption, (state, { payload }) => {
        state.paramsOption = { ...state.paramsOption, ...payload };
      })
      .addCase(resetSchedaParamsOption, (state) => {
        state.paramsOption = undefined;
      })
      .addCase(setSchedaSelectedIds, (state, { payload }) => {
        const ids = state.selectedIds;
        state.selectedIds = ids.includes(payload)
          ? ids.filter((currentId: string | number) => currentId !== payload)
          : [payload, ...state.selectedIds];
      })
      .addCase(resetSchedaSelectedIds, (state) => {
        state.selectedIds = [];
      })
      .addCase(updateSingleRecordSchede, (state, { payload }) => {
        if (state.data) {
          state.data = state.data?.map((row) =>
            Number(row.id) === Number(payload.id) ? payload : row
          );
        }
        if (state.dataOption) {
          state.dataOption = state.dataOption?.map((row) =>
            Number(row.id) === Number(payload.id) ? payload : row
          );
        }
      });
  },
});
