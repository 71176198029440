import { ActionType, UPDATE_SINGLE_RECORD } from '../../..';
import {
  fetchModuloChiavi,
  fetchModuloChiaviOptions,
  resetModuloChiaviParams,
  resetModuloChiaviParamsOption,
  resetModuloChiaviSelectedIds,
  resetModuloChiavi,
  resetModuloChiaviOption,
  setModuloChiaviParams,
  setModuloChiaviParamsOption,
  setModuloChiaviSelectedIds,
  selectModuloChiaviAll,
  updateSingleRecordModuloChiavi,
} from '../actions';

export const ModuloChiaviMapAction: Record<ActionType | string, any> = {
  fetchList: fetchModuloChiavi,
  fetchListOption: fetchModuloChiaviOptions,
  setParams: setModuloChiaviParams,
  setParamsOption: setModuloChiaviParamsOption,
  setSelectedIds: setModuloChiaviSelectedIds,
  resetSelectedIds: resetModuloChiaviSelectedIds,
  resetParams: resetModuloChiaviParams,
  resetParamsOption: resetModuloChiaviParamsOption,
  resetList: resetModuloChiavi,
  resetListOption: resetModuloChiaviOption,
  setSelectAll: selectModuloChiaviAll,
  deleteMulti: null,
  updateSingleRecord: updateSingleRecordModuloChiavi,
};
