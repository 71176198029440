import Icon from '@ailibs/feather-react-ts';
import { TextField } from '@mui/material';
import Box from 'components/Box';
import BoxIcon from 'components/BoxIcon';
import Flex from 'components/Flex';
import FormInput, { FormInputProps } from 'components/FormInput';
import Text from 'components/Text';
import { useDisclosure } from 'hooks';
import React, { ForwardedRef, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { KeyboardEventHandler } from 'react';
import { Badge } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { Option } from 'utils/types';

interface TagInputProps extends FormInputProps {
  tags?: string[];
  onChangeTagList?: (tags: string[]) => void;
}
/**
 * @todo modificare per insierimento valori fuori dal context useForm
 * @param param0
 * @returns
 */
export const TagInput = ({
  name,
  type = 'text',
  minHeight = '.25rem',
  tags,
  onChangeTagList,
  ...otherProps
}: TagInputProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tagData, setTagsData] = React.useState(tags ?? []);
  const [visualTags, setVisualTags] = useState<string[]>([]);
  const [iconModalTags, setIconModalTags] = useState<string>('plus');

  const createOption = (label: string) => ({
    label,
    value: label,
  });
  const handleClick = () => {
    setIconModalTags(!isOpen ? 'x' : tagData.length > 3 ? 'three-dots' : 'plus');
    setIsOpen(!isOpen);
  };
  const removeTagData = (indexToRemove: number) => {
    const newTags = [...tagData.filter((tag: string, index: number) => index !== indexToRemove)];
    setTagsData(newTags);
    onChangeTagList && onChangeTagList(newTags);
  };
  const addTagData = (event: any) => {
    if (event.target.value !== '') {
      const newTags = [...tagData, event.target.value];
      setTagsData(newTags);
      event.target.value = '';
      onChangeTagList && onChangeTagList(newTags);
    }
  };

  useEffect(() => {
    let newVisualTag: string[] = [];
    tagData.forEach((tag: string, index: number) => {
      index <= 2 && newVisualTag.push(tag);
    });
    setVisualTags(newVisualTag);
    !isOpen && setIconModalTags(tagData.length > 3 ? 'three-dots' : 'plus');
  }, [tagData, tagData.length]);

  return (
    <Flex
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      marginTop={'2.3rem'}
      marginRight={'1rem'}
      right={0}
      overflow="hidden"
      border="1px solid"
      minHeight={'8rem'}
      height={'12rem'}
      borderColor="primary"
      borderRadius={'5px'}
      className="scrollbar"
      overflowY="auto"
      zIndex={'1300'}
      padding="1rem"
    >
      <Box whiteSpace="normal" wordWrap="break-word">
        {tagData.map((tag: string, index: number) => (
          <Badge key={`details-${index}`} className="soft-primary" style={{ margin: '.3rem' }}>
            {tag} <Icon name="x-circle" onClick={() => removeTagData(index)} />
          </Badge>
        ))}
      </Box>
      <TextField
        id="standard-basic"
        label="Elenco valori da aggiungere"
        variant="standard"
        onKeyUp={(event: any) => (event?.key === 'Enter' ? addTagData(event) : null)}
      />
    </Flex>
    // <Flex position="static">
    //   <Flex
    //     margin="0px"
    //     border="1px solid"
    //     borderRadius="10px"
    //     borderColor="var(--bs-gray-light)"
    //     paddingTop=".3rem"
    //     paddingBottom=".3rem"
    //     paddingInline=".3rem"
    //     height="2.6rem"
    //     width="100%"
    //     justifyContent={'space-between'}
    //   >
    //     <Flex>
    //       {visualTags.length ? (
    //         visualTags.map((tag: string, index: number) => (
    //           <Flex
    //             className="btn-soft-primary"
    //             key={`value-${index}`}
    //             justifyContent="space-between"
    //             paddingTop="0"
    //             paddingLeft=".3rem"
    //             paddingBottom="0"
    //             marginLeft=".3rem"
    //             alignItems={'center'}
    //             borderRadius="10px"
    //             border="1px solid"
    //             borderColor="var(--bs-gray-light)"
    //           >
    //             <Box
    //               alignItems="center"
    //               cursor="default"
    //               width="3.3rem"
    //               fontSize={'1.2rem'}
    //               overflow="hidden"
    //               whiteSpace="nowrap"
    //               textOverflow={'ellipsis'}
    //             >
    //               {tag}
    //             </Box>
    //             <Icon
    //               name="x"
    //               onClick={() => removeTagData(index)}
    //               className="cursor-pointer"
    //               size="1.3rem"
    //             />
    //           </Flex>
    //         ))
    //       ) : (
    //         <Text text="Elenco valori vuoto" margin="0.1rem" />
    //       )}
    //     </Flex>
    //     <Box position="relative" onClick={handleClick}>
    //       <i className={`bi bi-${iconModalTags}`} style={{ fontSize: '1.5rem' }}></i>
    //     </Box>
    //     {isOpen && (
    //       <Flex
    //         display="flex"
    //         flexDirection="column"
    //         justifyContent="space-between"
    //         minWidth={'16rem'}
    //         maxWidth={'22rem'}
    //         width="max-content"
    //         position="absolute"
    //         marginTop={'2.3rem'}
    //         marginRight={'1rem'}
    //         right={0}
    //         overflow="hidden"
    //         border="1px solid"
    //         minHeight={'8rem'}
    //         height={'12rem'}
    //         maxHeight="12rem"
    //         borderColor="primary"
    //         borderRadius={'5px'}
    //         className="scrollbar"
    //         overflowY="auto"
    //         zIndex={'1300'}
    //         backgroundColor="var(--bs-gray-200)"
    //       >
    //         <Box whiteSpace="normal" wordWrap="break-word">
    //           {tagData.map((tag: string, index: number) => (
    //             <Badge
    //               key={`details-${index}`}
    //               className="soft-primary"
    //               style={{ margin: '.3rem' }}
    //             >
    //               {tag} <Icon name="x-circle" onClick={() => removeTagData(index)} />
    //             </Badge>
    //           ))}
    //         </Box>
    //         <FormInput
    //           onKeyUp={(event: any) => (event?.key === 'Enter' ? addTagData(event) : null)}
    //           placeholder="Inserisci qui eventuali valori..."
    //           name="name"
    //           style={{
    //             borderColor: 'var(--bs-gray-light)',
    //             padding: '0.12rem 0.4rem',
    //             borderRadius: 0,
    //           }}
    //           {...otherProps}
    //         />
    //       </Flex>
    //     )}
    //   </Flex>
    // </Flex>
  );
};

export default TagInput;
