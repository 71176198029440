import { createSelector } from 'reselect';
import { FAILED, PENDING, RootState, SUCCEEDED } from '../../..';

export const getAnagraficaState = (state: RootState) => state.anagrafica;

export const getAnagraficaLoader = createSelector(
  getAnagraficaState,
  (anagraficaState) => anagraficaState.loading === PENDING
);

export const getAnagraficaFailed = createSelector(
  getAnagraficaState,
  (anagraficaState) => anagraficaState.loading === FAILED
);

export const getAnagraficaSuccess = createSelector(
  getAnagraficaState,
  (anagraficaState) => anagraficaState.loading === SUCCEEDED
);

export const getAnagraficaError = createSelector(
  getAnagraficaState,
  (anagraficaState) => anagraficaState?.error
);

export const getAnagrafica = createSelector(
  getAnagraficaState,
  (anagraficaState) => anagraficaState?.data
);
