import React, {
  DOMAttributes,
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  ReactNode,
} from 'react';

interface FlexProps extends React.CSSProperties, DOMAttributes<HTMLDivElement> {
  id?: string;
  className?: string;
  ref?: any;
  children?: ReactNode;
}
export const Flex = forwardRef(
  (
    { id, className, children, display = 'flex', ...rest }: PropsWithChildren<FlexProps>,
    ref?: ForwardedRef<HTMLDivElement>
  ) => {
    const style: React.CSSProperties = rest;
    const elementAttributes: any = rest;
    type DomAttributesKeys = keyof DOMAttributes<HTMLDivElement>;
    const attrs = Object.keys(elementAttributes).reduce((accumulator: any, key: string) => {
      try {
        if (typeof elementAttributes[key] === 'function') {
          accumulator[key as DomAttributesKeys] = elementAttributes[key];
        }
        return accumulator;
      } catch (error) {
        console.log({ error });
      }
    }, {} as DOMAttributes<HTMLDivElement>);
    return (
      <div id={id} className={className} ref={ref} style={{ display: 'flex', ...style }} {...attrs}>
        {children}
      </div>
    );
  }
);

export default Flex;
