import { SelectorType } from '../../../utils/constants/selectorTypes';
import {
  getModuloChiave,
  getModuloChiaveFailed,
  getModuloChiaveLoader,
  getModuloChiaveSuccess,
} from '../selectors';

export const ModuloChiaveMapSelector = {
  loadingElement: getModuloChiaveLoader,
  successElement: getModuloChiaveSuccess,
  failedElement: getModuloChiaveFailed,
  element: getModuloChiave,
};

export default ModuloChiaveMapSelector;
