export const GENERIC_ERROR = 'error.status.generic';
export const FUNCTION_NOT_IMPLEMENTED = 'error.status.function_not_implemented';
export const DELETE_MULTI_ERROR = 'error.status.delete_multi';
export const DELETE_MULTI_NONE_ERROR = 'error.status.delete_multi_none';
export const ERRORS: Record<string | number, string> = {
  400: 'Errore! Richiesta non valida',
  401: 'Utente non autorizzato.',
  500: 'Errore generico sulla richiesta.',
  [GENERIC_ERROR]: 'Errore generico sulla richiesta.',
  [FUNCTION_NOT_IMPLEMENTED]: 'Funzione non ancora implementata',
  [DELETE_MULTI_ERROR]: 'Errore cancellazione',
  [DELETE_MULTI_NONE_ERROR]: 'Errore cancellazione',
};

export const ERRORS_FOR_ACTION = {
  ...ERRORS,
};
