import { createSelector } from 'reselect';
import { IDLE, PENDING, FAILED, SUCCEEDED, RootState, Info } from '../../../..';
import { Referente } from '../../referente';

export const getReferentiState = (state: RootState) => state.referenti;

export const getReferentiLoader = createSelector(
  getReferentiState,
  (referentiState) => referentiState.loading === PENDING
);

export const getReferentiFailed = createSelector(
  getReferentiState,
  (referentiState) => referentiState.loading === FAILED
);

export const getReferentiSuccess = createSelector(
  getReferentiState,
  (referentiState) => referentiState.loading === SUCCEEDED
);

export const getReferentiOptionLoader = createSelector(
  getReferentiState,
  (referentiState) => referentiState.loadingOption === PENDING
);

export const getReferentiOptionFailed = createSelector(
  getReferentiState,
  (referentiState) => referentiState.loadingOption === FAILED
);

export const getReferentiOptionSuccess = createSelector(
  getReferentiState,
  (referentiState) => referentiState.loadingOption === SUCCEEDED
);

export const getReferentiEnumLoader = createSelector(
  getReferentiState,
  (referentiState) => referentiState.loadingEnum === PENDING
);

export const getReferentiEnumFailed = createSelector(
  getReferentiState,
  (referentiState) => referentiState.loadingEnum === FAILED
);

export const getReferentiEnumSuccess = createSelector(
  getReferentiState,
  (referentiState) => referentiState.loadingEnum === SUCCEEDED
);

export const getReferentiError = createSelector(
  getReferentiState,
  (referentiState) => referentiState?.error
);

export const getReferenti = createSelector(
  getReferentiState,
  (referentiState) => referentiState?.data
);

export const getReferentiInfo = createSelector(
  getReferentiState,
  (referentiState): Info => referentiState?.info ?? { pages: 0, currentPage: 0, total: 0 }
);

export const getReferentiOption = createSelector(
  getReferentiState,
  (referentiState) => referentiState?.dataOption
);

export const getReferentiOptionInfo = createSelector(
  getReferentiState,
  (referentiState) => referentiState?.infoOption
);

export const getCategorieReferente = createSelector(
  getReferentiState,
  (referentiState) => referentiState?.categorie
);

export const getCategoriaReferente = (gruppo: string | number) =>
  createSelector(getCategorieReferente, (categorie) => {
    const gruppoElement = categorie
      ? categorie.find((element: Record<string, any>) => element.id === gruppo)
      : null;
    return gruppoElement;
  });

export const getReferentiTipologie = createSelector(
  getReferentiState,
  (referentiState) => referentiState?.tipologie
);

export const getReferentiKeyValues = createSelector(
  getReferenti,
  (referenti) =>
    referenti?.map((referente: Referente) => ({
      id: referente.id,
      descrizione: `${referente.nome} ${referente.cognome}`,
    })) ?? []
);
export const getReferentiOptionKeyValues = createSelector(
  getReferentiOption,
  (referenti) =>
    referenti?.map((referente: Referente) => ({
      id: referente.id,
      descrizione: `${referente.nome} ${referente.cognome}`,
    })) ?? []
);

export const getReferenteById = (id: string | number) =>
  createSelector(getReferenti, (referenti) =>
    referenti?.find((referenteElement: any) => referenteElement.id === id)
  );

export const getReferenteOptionById = (id: string | number) =>
  createSelector(getReferentiOption, (referenti) =>
    referenti?.find((referenteElement: any) => referenteElement.id === id)
  );

export const getReferenteTipologia = (tipologia: string | number) =>
  createSelector(getReferentiTipologie, (tipologie) => {
    const tipologiaElement = tipologie
      ? tipologie.find((element: Record<string, any>) => element.id === tipologia)
      : null;
    return tipologiaElement;
  });

export const getReferentiParams = createSelector(
  getReferentiState,
  (referentiState) => referentiState?.params
);

export const getReferentiParamsOption = createSelector(
  getReferentiState,
  (referentiState) => referentiState?.paramsOption
);

export const getReferentiSelectedIds = createSelector(
  getReferentiState,
  (referentiState) => referentiState?.selectedIds
);
