import { ActionType, UPDATE_SINGLE_RECORD } from '../../..';
import {
  fetchModuli,
  fetchModuliOptions,
  resetModuloParams,
  resetModuloParamsOption,
  resetModuloSelectedIds,
  resetModuli,
  resetModuliOption,
  setModuloParams,
  setModuloParamsOption,
  setModuloSelectedIds,
  selectModuloAll,
  deleteModuliMulti,
  updateSingleRecordModuli,
} from '../actions';

export const ModuliMapAction: Record<ActionType | string, any> = {
  fetchList: fetchModuli,
  fetchListOption: fetchModuliOptions,
  setParams: setModuloParams,
  setParamsOption: setModuloParamsOption,
  setSelectedIds: setModuloSelectedIds,
  resetSelectedIds: resetModuloSelectedIds,
  resetParams: resetModuloParams,
  resetParamsOption: resetModuloParamsOption,
  resetList: resetModuli,
  resetListOption: resetModuliOption,
  setSelectAll: selectModuloAll,
  deleteMulti: deleteModuliMulti,
  updateSingleRecord: updateSingleRecordModuli,
};
