import { createSelector } from 'reselect';
import { FAILED, PENDING, RootState, SUCCEEDED } from '../../..';

export const getImportazioneState = (state: RootState) => state.importazione;

export const getImportazioneLoader = createSelector(
  getImportazioneState,
  (importazioneState) => importazioneState.loading === PENDING
);

export const getImportazioneFailed = createSelector(
  getImportazioneState,
  (importazioneState) => importazioneState.loading === FAILED
);

export const getImportazioneSuccess = createSelector(
  getImportazioneState,
  (importazioneState) => importazioneState.loading === SUCCEEDED
);

export const getImportazioneError = createSelector(
  getImportazioneState,
  (importazioneState) => importazioneState?.error
);

export const getImportazione = createSelector(
  getImportazioneState,
  (importazioneState) => importazioneState?.data
);

export const getImportazioneHeader = createSelector(
  getImportazioneState,
  (importazioneState) => importazioneState?.header
);

export const getImportazioneRows = createSelector(
  getImportazioneState,
  (importazioneState) => importazioneState?.rows
);

export const getImportazioneAdded = createSelector(
  getImportazioneState,
  (importazioneState) => importazioneState?.added
);

export const getImportazioneUpdated = createSelector(
  getImportazioneState,
  (importazioneState) => importazioneState?.updated
);

export const getImportazioneNotImported = createSelector(
  getImportazioneState,
  (importazioneState) => importazioneState?.notImported
);
