import { createSelector } from 'reselect';
import { IDLE, PENDING, FAILED, SUCCEEDED, RootState, Info, ModuloChiave } from '../../..';

export const getModuloChiaviState = (state: RootState) => state.moduloChiavi;

export const getModuloChiaviLoader = createSelector(
  getModuloChiaviState,
  (moduloChiaviState) => moduloChiaviState.loading === PENDING
);

export const getModuloChiaviFailed = createSelector(
  getModuloChiaviState,
  (moduloChiaviState) => moduloChiaviState.loading === FAILED
);

export const getModuloChiaviSuccess = createSelector(
  getModuloChiaviState,
  (moduloChiaviState) => moduloChiaviState.loading === SUCCEEDED
);

export const getModuloChiaviOptionLoader = createSelector(
  getModuloChiaviState,
  (moduloChiaviState) => moduloChiaviState.loadingOption === PENDING
);

export const getModuloChiaviOptionFailed = createSelector(
  getModuloChiaviState,
  (moduloChiaviState) => moduloChiaviState.loadingOption === FAILED
);

export const getModuloChiaviOptionSuccess = createSelector(
  getModuloChiaviState,
  (moduloChiaviState) => moduloChiaviState.loadingOption === SUCCEEDED
);

export const getModuloChiaviEnumLoader = createSelector(
  getModuloChiaviState,
  (moduloChiaviState) => moduloChiaviState.loadingEnum === PENDING
);

export const getModuloChiaviEnumFailed = createSelector(
  getModuloChiaviState,
  (moduloChiaviState) => moduloChiaviState.loadingEnum === FAILED
);

export const getModuloChiaviEnumSuccess = createSelector(
  getModuloChiaviState,
  (moduloChiaviState) => moduloChiaviState.loadingEnum === SUCCEEDED
);

export const getModuloChiaviError = createSelector(
  getModuloChiaviState,
  (moduloChiaviState) => moduloChiaviState?.error
);

export const getModuloChiavi = createSelector(
  getModuloChiaviState,
  (moduloChiaviState): ModuloChiave[] => moduloChiaviState?.data
);

export const getModuloChiaviInfo = createSelector(
  getModuloChiaviState,
  (moduloChiaviState): Info => moduloChiaviState?.info ?? { pages: 0, currentPage: 0, total: 0 }
);

export const getModuloChiaviOption = createSelector(
  getModuloChiaviState,
  (moduloChiaviState) => moduloChiaviState?.dataOption ?? []
);

export const getModuloChiaviOptionInfo = createSelector(
  getModuloChiaviState,
  (moduloChiaviState) => moduloChiaviState?.infoOption
);

export const getModuloChiaviTipologie = createSelector(
  getModuloChiaviState,
  (moduloChiaviState) => moduloChiaviState?.tipologie
);

export const getModuloChiaveById = (id: string | number) =>
  createSelector(
    getModuloChiavi,
    (moduloChiavi) =>
      moduloChiavi?.find((moduloChiaveElement) => moduloChiaveElement.id === id) ?? null
  );

export const getModuloChiaveOptionById = (id: string | number) =>
  createSelector(
    getModuloChiaviOption,
    (moduloChiavi) =>
      moduloChiavi?.find((moduloChiaveElement) => moduloChiaveElement.id === id) ?? null
  );

export const getModuloChiaviParams = createSelector(
  getModuloChiaviState,
  (moduloChiaviState) => moduloChiaviState?.params
);

export const getModuloChiaviParamsOption = createSelector(
  getModuloChiaviState,
  (moduloChiaviState) => moduloChiaviState?.paramsOption
);

export const getModuloChiaviSelectedIds = createSelector(
  getModuloChiaviState,
  (moduloChiaviState) => moduloChiaviState?.selectedIds
);
