export interface Modulo {
  id?: number;
  nome: string | null;
  created?: Date;
  updated?: Date;
}

export class ModuloClass implements Modulo {
  id = 0;
  nome = null;
  created = undefined;
  updated = undefined;
}
