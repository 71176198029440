import { createSelector } from 'reselect';
import { RootState } from '../../applicationStore';
import { SideBarTypes, Theme, LayoutTypes, LayoutWidth, Layout } from '../types';

export const getLayoutState = (state: RootState) => state.layout;

export const getSidebarType = createSelector(
  getLayoutState,
  (layoutState): SideBarTypes => layoutState.leftSideBarType
);

export const getSidebarTheme = createSelector(
  getLayoutState,
  (layoutState): Theme => layoutState.leftSideBarTheme
);

export const getTopbarTheme = createSelector(
  getLayoutState,
  (layoutState): Theme => layoutState.topbarTheme
);

export const getLayoutType = createSelector(
  getLayoutState,
  (layoutState): LayoutTypes => layoutState.layoutType
);

export const getLayoutWidth = createSelector(
  getLayoutState,
  (layoutState): LayoutWidth => layoutState.layoutWidth
);

export const getShowSidebarUserInfo = createSelector(
  getLayoutState,
  (layoutState): boolean => layoutState.showSidebarUserInfo
);

export const getLayout = createSelector(getLayoutState, (layoutState): Layout => layoutState);
