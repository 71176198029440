const LOADING = 'loading';
const SUCCESS = 'success';
const FAILED = 'failed';
const DATA = 'data';
const INFO = 'info';
const PARAMS = 'params';
const LOADING_OPTION = 'loadingOption';
const SUCCESS_OPTION = 'successOption';
const FAILEDOPTION = 'failedOption';
const DATA_OPTION = 'dataOption';
const INFO_OPTION = 'infoOption';
const PARAMS_OPTION = 'paramsOption';
const SELECTED_IDS = 'selectedIds';
const ELEMENT_BY_ID = 'elementById';
const ELEMENT_OPTION_BY_ID = 'elementOptionById';
const ELEMENT = 'element';
const LOADING_ELEMENT = 'loadingElement';
const SUCCESS_ELEMENT = 'successElement';
const FAILED_ELEMENT = 'failedElement';
const SELECT_KEY_VALUES = 'selectKeyValues';
const SELECT_OPTION_KEY_VALUES = 'selectOptionKeyValues';

export type SelectorType =
  | 'loading'
  | 'success'
  | 'failed'
  | 'data'
  | 'info'
  | 'params'
  | 'loadingOption'
  | 'successOption'
  | 'failedOption'
  | 'dataOption'
  | 'infoOption'
  | 'paramsOption'
  | 'selectedIds'
  | 'elementById'
  | 'elementOptionById'
  | 'element'
  | 'loadingElement'
  | 'successElement'
  | 'failedElement'
  | 'selectKeyValues'
  | 'selectOptionKeyValues';

export const selectorTypes: Record<SelectorType, string> = {
  [LOADING]: 'loading',
  [SUCCESS]: 'success',
  [FAILED]: 'failed',
  [DATA]: 'data',
  [INFO]: 'info',
  [PARAMS]: 'params',
  [LOADING_OPTION]: 'loadingOption',
  [SUCCESS_OPTION]: 'successOption',
  [FAILEDOPTION]: 'failedOption',
  [DATA_OPTION]: 'dataOption',
  [INFO_OPTION]: 'infoOption',
  [PARAMS_OPTION]: 'paramsOption',
  [SELECTED_IDS]: 'selectedIds',
  [ELEMENT_BY_ID]: 'elementById',
  [ELEMENT_OPTION_BY_ID]: 'elementOptionById',
  [ELEMENT]: 'element',
  [LOADING_ELEMENT]: 'loadingElement',
  [SUCCESS_ELEMENT]: 'successElement',
  [FAILED_ELEMENT]: 'failedElement',
  [SELECT_KEY_VALUES]: SELECT_KEY_VALUES,
  [SELECT_OPTION_KEY_VALUES]: SELECT_OPTION_KEY_VALUES,
};
