import { selectorTypes } from '../../..';
import {
  getFiltri,
  getFiltriFailed,
  getFiltriInfo,
  getFiltriKeyValues,
  getFiltriLoader,
  getFiltriOption,
  getFiltriOptionFailed,
  getFiltriOptionInfo,
  getFiltriOptionKeyValues,
  getFiltriOptionLoader,
  getFiltriOptionSuccess,
  getFiltriParams,
  getFiltriParamsOption,
  getFiltriSelectedIds,
  getFiltriSuccess,
  getFiltroById,
  getFiltroOptionById,
} from '../selectors';

export const FiltriMapSelector = {
  loading: getFiltriLoader,
  success: getFiltriSuccess,
  failed: getFiltriFailed,
  data: getFiltri,
  info: getFiltriInfo,
  params: getFiltriParams,
  loadingOption: getFiltriOptionLoader,
  successOption: getFiltriOptionSuccess,
  failedOption: getFiltriOptionFailed,
  dataOption: getFiltriOption,
  infoOption: getFiltriOptionInfo,
  paramsOption: getFiltriParamsOption,
  selectedIds: getFiltriSelectedIds,
  selectKeyValues: getFiltriKeyValues,
  selectOptionKeyValues: getFiltriOptionKeyValues,
  elementById: getFiltroById,
  elementOptionById: getFiltroOptionById,
};

export default FiltriMapSelector;
