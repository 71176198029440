import { createSelector } from 'reselect';
import { RootState } from '../../applicationStore';
import { PENDING, FAILED, SUCCEEDED } from '../../types';

export const getAuthState = (state: RootState) => state.auth;

export const getAuthLoader = createSelector(
  getAuthState,
  (authState) => authState.loading === PENDING
);

export const getAuthFailed = createSelector(
  getAuthState,
  (authState) => authState.loading === FAILED
);

export const getAuthSuccess = createSelector(
  getAuthState,
  (authState) => authState.loading === 'succeeded'
);

export const getAuthError = createSelector(getAuthState, (authState) => authState?.error);

export const getIsAuthenticated = createSelector(
  getAuthState,
  (authState) => !!authState?.data?.isAuthenticated
);

export const getToken = createSelector(getAuthState, (authState) => authState?.data?.token || '');
export const getRegisterFailed = createSelector(
  getAuthState,
  (authState) => authState.loading === FAILED && !authState?.registerData?.email
);
export const registeredSuccess = createSelector(
  getAuthState,
  (authState) => authState.loading === SUCCEEDED && !!authState?.registerData?.email
);

export const emailRegistration = createSelector(
  getAuthState,
  (authState) => authState?.registerData?.email ?? null
);
