import { ActionType } from '../../..';
import { fetchScheda, createScheda, deleteScheda, resetScheda } from '../actions';

export const SchedaMapAction: Record<ActionType | string, any> = {
  fetch: fetchScheda,
  submit: createScheda,
  delete: deleteScheda,
  reset: resetScheda,
  deleteEmptyRows: deleteScheda,
};

export default SchedaMapAction;