import { createSlice } from '@reduxjs/toolkit';
import { IDLE, PENDING, FAILED, SUCCEEDED } from '../../../types';
import { fetchCap, resetCapSearch } from '../actions';
import { StateAddressCap } from '../types';

const initialState: StateAddressCap = {
  data: [],
  loading: IDLE,
  error: null,
};

export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCap.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.data = data ?? [];
        state.loading = SUCCEEDED;
        state.error = null;
      })
      .addCase(fetchCap.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(fetchCap.rejected, (state, action) => {
        state.error = action?.error?.code || action?.error?.name || '';
        state.loading = FAILED;
      })
      .addCase(resetCapSearch, (state) => initialState);
  },
});
