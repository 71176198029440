import { DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';
import { CSSProperties } from 'react';

export const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getListStyle = (isDraggingOver: boolean): CSSProperties | undefined => ({
  background: isDraggingOver ? '' : '',
});

export const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
  themeColor: string
): CSSProperties | undefined => ({
  background: isDragging ? themeColor : '',
  ...draggableStyle,
});
