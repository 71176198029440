import { ActionType, UPDATE_SINGLE_RECORD } from '../../../..';
import {
  fetchReferenti,
  fetchReferentiOptions,
  resetReferenteParams,
  resetReferenteParamsOption,
  resetReferenteSelectedIds,
  resetReferenti,
  resetReferentiOption,
  setReferenteParams,
  setReferenteParamsOption,
  setReferenteSelectedIds,
  selectReferenteAll,
  deleteReferentiMulti,
  updateSingleRecordReferenti,
} from '../actions';

export const ReferentiMapAction: Record<ActionType | string, any> = {
  fetchList: fetchReferenti,
  fetchListOption: fetchReferentiOptions,
  setParams: setReferenteParams,
  setParamsOption: setReferenteParamsOption,
  setSelectedIds: setReferenteSelectedIds,
  resetSelectedIds: resetReferenteSelectedIds,
  resetParams: resetReferenteParams,
  resetParamsOption: resetReferenteParamsOption,
  resetList: resetReferenti,
  resetListOption: resetReferentiOption,
  setSelectAll: selectReferenteAll,
  deleteMulti: deleteReferentiMulti,
  updateSingleRecord: updateSingleRecordReferenti,
};
