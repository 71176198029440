import { createSelector } from 'reselect';
import { Modulo } from 'store/moduli/modulo';
import { IDLE, PENDING, FAILED, SUCCEEDED, RootState, Info } from '../../..';

export const getModuliState = (state: RootState) => state.moduli;

export const getModuliLoader = createSelector(
  getModuliState,
  (moduliState) => moduliState.loading === PENDING
);

export const getModuliFailed = createSelector(
  getModuliState,
  (moduliState) => moduliState.loading === FAILED
);

export const getModuliSuccess = createSelector(
  getModuliState,
  (moduliState) => moduliState.loading === SUCCEEDED
);

export const getModuliOptionLoader = createSelector(
  getModuliState,
  (moduliState) => moduliState.loadingOption === PENDING
);

export const getModuliOptionFailed = createSelector(
  getModuliState,
  (moduliState) => moduliState.loadingOption === FAILED
);

export const getModuliOptionSuccess = createSelector(
  getModuliState,
  (moduliState) => moduliState.loadingOption === SUCCEEDED
);

export const getModuliEnumLoader = createSelector(
  getModuliState,
  (moduliState) => moduliState.loadingEnum === PENDING
);

export const getModuliEnumFailed = createSelector(
  getModuliState,
  (moduliState) => moduliState.loadingEnum === FAILED
);

export const getModuliEnumSuccess = createSelector(
  getModuliState,
  (moduliState) => moduliState.loadingEnum === SUCCEEDED
);

export const getModuliError = createSelector(getModuliState, (moduliState) => moduliState?.error);

export const getModuli = createSelector(
  getModuliState,
  (moduliState): Modulo[] => moduliState?.data
);

export const getModuliInfo = createSelector(
  getModuliState,
  (moduliState): Info => moduliState?.info ?? { pages: 0, currentPage: 0, total: 0 }
);

export const getModuliOption = createSelector(
  getModuliState,
  (moduliState) => moduliState?.dataOption
);

export const getModuliOptionInfo = createSelector(
  getModuliState,
  (moduliState) => moduliState?.infoOption
);

export const getModuliKeyValues = createSelector(getModuli, (moduli) => moduli?.map((modulo: Modulo) => ({ id: modulo.id, descrizione: modulo.nome })) ?? []);
export const getModuliOptionKeyValues = createSelector(getModuliOption, (moduli) => moduli?.map((modulo: Modulo) => ({ id: modulo.id, descrizione: modulo.nome })) ?? []);


export const getModuloById = (id: string | number) =>
  createSelector(getModuli, (moduli) => moduli?.find((moduloElement) => moduloElement.id === id));

export const getModuloOptionById = (id: string | number) =>
  createSelector(getModuliOption, (moduli) =>
    moduli?.find((moduloElement) => moduloElement.id === id)
  );

export const getModuliParams = createSelector(getModuliState, (moduliState) => moduliState?.params);

export const getModuliParamsOption = createSelector(
  getModuliState,
  (moduliState) => moduliState?.paramsOption
);

export const getModuliSelectedIds = createSelector(
  getModuliState,
  (moduliState) => moduliState?.selectedIds
);
