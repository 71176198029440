import { createSelector } from 'reselect';
import { FAILED, PENDING, RootState, SUCCEEDED } from '../../..';

export const getModuloChiaveState = (state: RootState) => state.moduloChiave;

export const getModuloChiaveLoader = createSelector(
  getModuloChiaveState,
  (moduloChiaveState) => moduloChiaveState.loading === PENDING
);

export const getModuloChiaveFailed = createSelector(
  getModuloChiaveState,
  (moduloChiaveState) => moduloChiaveState.loading === FAILED
);

export const getModuloChiaveSuccess = createSelector(
  getModuloChiaveState,
  (moduloChiaveState) => moduloChiaveState.loading === SUCCEEDED
);

export const getModuloChiaveError = createSelector(
  getModuloChiaveState,
  (moduloChiaveState) => moduloChiaveState?.error
);

export const getModuloChiave = createSelector(
  getModuloChiaveState,
  (moduloChiaveState) => moduloChiaveState?.data
);
export const getModuloChiaviElencoValori = () =>
  createSelector(getModuloChiave, (moduloChiave) => moduloChiave?.elenco_valori ?? []);
