import Icon, { IconName } from '@ailibs/feather-react-ts';
import useHover from 'hooks/useHover';
import React, { DOMAttributes, memo, ReactElement, useRef } from 'react';
import Box from './Box';

interface Props extends React.CSSProperties, DOMAttributes<HTMLDivElement> {
  iconName: IconName;
  iconSize?: number;
  isDisabled?: boolean;
  className?: string;
}

export const BoxIcon = ({
  iconName,
  iconSize = 1.6,
  color = 'primary',
  cursor = 'pointer',
  isDisabled = false,
  className,
  onClick,
  ...rest
}: Props): ReactElement => {
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);
  return (
    <Box
      cursor={isDisabled ? 'default' : cursor}
      ref={hoverRef}
      onClick={onClick}
      className={className}
      {...rest}
    >
      <Icon name={iconName} size={`${iconSize}rem`} />
    </Box>
  );
};

export default memo(BoxIcon);
