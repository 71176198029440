import { ActionType } from '../../..';
import { createModulo, deleteModulo, fetchModulo, resetModulo } from '../actions';
import { deleteEmptyRowModulo } from '../actions/deleteEmptyRows';

export const ModuloMapAction: Record<ActionType | string, any> = {
  fetch: fetchModulo,
  submit: createModulo,
  delete: deleteModulo,
  reset: resetModulo,
  deleteEmptyRows: deleteEmptyRowModulo,
};
