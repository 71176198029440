import { getUser } from './../store/user/selectors/index';
import { yupResolver } from '@hookform/resolvers/yup';
import { AlertMessage } from 'components/AlertMessage';
import Forms from 'components/Form/children';
import MapEmptyElement from 'components/Form/mapEmptyElement';
import MapValidationSchemas from 'components/Form/mapValidations';
import { keyify, trimStringFromObject, findNestedProp, deepMerge } from 'helpers';
import React from 'react';
import { useCallback, useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  CONTO_ID,
  DELETE_EMPTY_ROWS,
  DRIVE_TEST_ID,
  FETCH,
  FETCHLIST,
  fetchUser,
  getAction,
  getErrorLock,
  getErrorMessage,
  getSelectorElement,
  getSelectorElementById,
  getSelectorLoadingElement,
  RESET,
  RESET_ALL,
  resetError,
  SCHEDA_ID,
  SUBMIT,
  TabsId,
  useAppDispatch,
  UTENTI_ID,
  VALIDAZIONE_SCHEDA,
  VALIDAZIONE_SCHEDA_ID,
} from 'store';
import { LockStatus, lock, unlock } from 'store/lock';
import { ModuleName } from 'utils';
import useDisclosure from './useDisclosure';
import { Tab } from 'react-bootstrap';

export interface UseFormsProps {
  id: number | null;
  tabId: TabsId;
  handleClose: () => void;
  parentId?: number;
  showAlertCreate?: boolean;
  fromOptions?: boolean;
  deleteEmptyChild?: boolean;
  module?: ModuleName;
  customSaveAndClose?: () => void;
}

export interface UseFormsReturn {
  methods: UseFormReturn<any, any>;
  closeAndResetForm: () => void;
  handleSave: () => void;
  handleSaveAndClose: () => void;
  isLoading: boolean;
  element: any;
  handleSaveCurrentTab: () => Promise<any>;
  handleUnlock: () => void;
  hanldeLockStatus: () => Promise<LockStatus | null>;
  isDisabled: boolean;
  errorLock?: LockStatus | null;
}

export const useCrudForm = ({
  id,
  tabId,
  handleClose,
  parentId,
  showAlertCreate = true,
  fromOptions = false,
  deleteEmptyChild = false,
  module,
  customSaveAndClose,
}: UseFormsProps): UseFormsReturn => {
  const element: any = getSelectorElementById<any>(tabId, id ?? 0, fromOptions);
  const elementData: any = getSelectorElement<any>(tabId);
  const [isDisabled, setIsDisabled] = React.useState(true);

  const user = useSelector(getUser);

  const errorLock = useSelector(getErrorLock);

  const getElement = useCallback(() => {
    console.log({ id, elementData, element });
    if (!element && elementData && elementData?.id === id) return elementData;
    if (!!element && element?.id !== elementData?.id) {
      return element;
    }

    return elementData ?? element;
  }, [id, elementData]);

  const emptyElement = MapEmptyElement[tabId];

  const schema = MapValidationSchemas[tabId];

  const isLoading = getSelectorLoadingElement(tabId);
  const methods = useForm<any>({
    mode: 'onBlur',
    defaultValues: emptyElement,
    resolver: yupResolver(schema),
  });

  const dispatch = useAppDispatch();

  const avoidResetForm = [CONTO_ID];
  const resetTabIdParent: Record<string, TabsId> = {
    [VALIDAZIONE_SCHEDA_ID]: SCHEDA_ID,
  };
  const avoidFetchList = [CONTO_ID, VALIDAZIONE_SCHEDA_ID];

  // const errorMessage: string = useSelector(getErrorMessage);

  // const errorDisclosure = useDisclosure();
  // useEffect(() => {
  //   errorMessage && errorDisclosure.onOpen();
  // }, [errorMessage]);

  // useEffect(() => {
  //   if (!errorDisclosure.isOpen) {
  //     dispatch(resetError());
  //   }
  // }, [errorDisclosure.isOpen]);

  const {
    trigger,
    getValues,
    setValue,
    reset,
    register,
    control,
    watch,
    formState: { errors, dirtyFields, isDirty, touchedFields },
    clearErrors,
  } = methods;

  const deleteEmptyRows = async () => {
    if (deleteEmptyChild && id) {
      const action = getAction(tabId, DELETE_EMPTY_ROWS);
      if (action) {
        await dispatch(action({ id }));
      }
    }
  };

  const closeAndResetForm = () => {
    resetStore(id, true);
    if (!avoidResetForm.includes(tabId)) reset(emptyElement);
    deleteEmptyRows();
    handleClose();
  };

  const getValuesForm = (): any => {
    const item = getValues() as any;
    const keys = keyify(emptyElement);
    const keysObject = Object.keys(emptyElement);
    const request = keys.reduce((accumulator: any, key) => {
      accumulator[key] = item[key] ?? emptyElement[key];
      return accumulator;
    }, {});
    return trimStringFromObject(request);
  };

  const actionSave = useCallback(async (): Promise<any | null> => {
    const noError = await trigger();
    const item = getValuesForm();
    if (id) {
      item.id = id;
    }
    if (parentId) item.parentId = parentId;

    if (noError) {
      const actionCreate = getAction(tabId, SUBMIT);

      const response = await dispatch(actionCreate(item));
      reset(item as any);
      if (tabId === UTENTI_ID && user && user.email === item.email) {
        await dispatch(fetchUser());
      }
      return response;
    }
    return null;
  }, [id, tabId]);

  const resetStore = useCallback(
    (id: number | null, close: boolean) => {
      console.log('resetStore', { id, close });
      if (close) {
        let tabIdForm = tabId;
        if (tabId in resetTabIdParent) {
          tabIdForm = resetTabIdParent[tabId];
        }

        const resetAction = getAction(tabIdForm, RESET_ALL);
        console.log({ resetAction, tabIdForm });
        if (!avoidResetForm.includes(tabIdForm)) {
          if (resetAction) dispatch(resetAction());
          else {
            const resetElement = getAction(tabIdForm, RESET);
            resetElement && dispatch(resetElement());
          }
        }
      }
    },
    [id]
  );

  const save = useCallback(
    async (close: boolean): Promise<any | null> => {
      const response = await actionSave();
      if (!!response && !response.error) {
        const elementResponse = response?.payload['data'];
        // @ts-ignore
        setValue('id', elementResponse?.id);
        resetStore(elementResponse?.id, close);

        const actionList = getAction(tabId, FETCHLIST, fromOptions);
        actionList && dispatch(actionList(fromOptions && parentId ? { parentId } : undefined));
        showAlertCreate &&
          AlertMessage({
            title: 'Perfetto',
            text: 'Record salvato con successo',
            icon: 'success',
            allowOutsideClick: false,
          });
        return elementResponse;
      }
      return null;
    },
    [id, tabId]
  );

  const handleSave = async (): Promise<any> => {
    return await save(false);
  };

  const handleSaveAndReset = async (): Promise<void> => {
    return await save(true);
  };

  const handleSaveWithCheck = async (): Promise<boolean> => {
    const elementResponse = await save(false);

    return !!elementResponse;
  };

  const handleSaveAndClose = async (): Promise<void> => {
    if (customSaveAndClose) {
      handleClose();
      !avoidResetForm.includes(tabId) && reset(emptyElement);
      deleteEmptyRows();
      customSaveAndClose();
      return;
    }
    const elementResponse = await save(true);
    console.log({ tabId, elementResponse });
    if (elementResponse) {
      handleClose();
      !avoidResetForm.includes(tabId) && reset(emptyElement);
      deleteEmptyRows();
    }
  };

  const handleSaveCurrentTab = async (): Promise<any> => {
    if (Object.keys(dirtyFields).length) {
      const response = await actionSave();
      const id = response?.payload?.data?.id;
      setValue('id', id);
      const actionList = getAction(tabId, FETCHLIST, fromOptions);
      dispatch(actionList(fromOptions && parentId ? { parentId } : undefined));
      return response?.payload?.data;
    }
    await trigger();
  };

  const handleLock = async () => {
    try {
      const response = await dispatch(
        lock({ module: module as ModuleName, module_id: id as number })
      );
      if (response && !response.error) {
        const lock = response.payload.data;
        setIsDisabled(
          !!(
            lock.email !== user.email &&
            lock.module === module &&
            Number(lock.module_id) === Number(id)
          )
        );
        return lock;
      }
      return null;
    } catch (error) {
      console.error({ error });
    }
  };

  const handleUnlock = () => {
    dispatch(unlock({ module: module as ModuleName, module_id: id as number }));
    setIsDisabled(true);
  };

  useEffect(() => setIsDisabled(true), [id]);

  useEffect(() => {
    const formElement = getElement();
    if (id && formElement) {
      const keys = keyify(emptyElement) as string[];
      keys.forEach((key) => {
        if (formElement) {
          return setValue(key as any, formElement[key]);
        }
      });
    } else {
      reset(emptyElement);
    }
  }, [element, id, setValue]);

  useEffect(() => {
    if (errorLock) {
      const { module: lockModule, email, module_id } = errorLock;

      setIsDisabled(
        email !== user.email && module === lockModule && Number(module_id) === Number(id)
      );
    }
  }, [JSON.stringify(errorLock)]);

  return {
    methods,
    closeAndResetForm,
    handleSave,
    handleSaveAndClose,
    isLoading,
    element: getElement(),
    handleSaveCurrentTab,
    hanldeLockStatus: handleLock,
    handleUnlock,
    isDisabled,
    errorLock,
  };
};
