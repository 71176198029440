import { Flex, Text, Box } from 'components';
import FormContext from 'components/Form/FormContext';
import React from 'react';

interface NoRowsOverlayInterface {
  onClickNew: (formId: number) => Promise<void>;
  onClickNewWithOutFormId: () => Promise<void>;
}

export const NoRowsOverlay = ({ onClickNew, onClickNewWithOutFormId }: NoRowsOverlayInterface) => {
  const { isDisabled, formId } = React.useContext(FormContext);
  return (
    <Flex flexDirection="column" alignItems="center" height="4rem">
      <Text
        fontSize="sm"
        fontWeight="medium"
        color={isDisabled ? 'text05' : 'text01'}
        text="No Data"
      />
      <Box height="2rem" />
      <button
        type="button"
        className="cursor-pointer rounded-pill dropdown-toggle btn btn-soft-primary"
        disabled={false}
        style={{
          bottom: 0,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          zIndex: '10000',
        }}
        onClick={() => {
          formId ? onClickNew(formId) : onClickNewWithOutFormId();
        }}
      >
        <i className="bi bi-plus-circle-fill" style={{ fontSize: '.95rem' }}></i>
        <Text text="Aggiungi nuovo" margin="0.1rem" />
      </button>
    </Flex>
  );
};
