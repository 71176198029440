import { KeyValue } from 'store/types';

export interface Referente {
  id?: number;
  anagrafica_id: number;
  piva: string | null;
  titolo: string | null;
  ruolo: string | null;
  nome: string | null;
  cognome: string | null;
  telefono: string | null;
  cellulare: string | null;
  presente_whatsapp: boolean;
  disabilitato: boolean;
  email: string | null;
  created?: Date;
  updated?: Date;
}

export class ReferenteClass implements Referente {
  id = 0;
  anagrafica_id = 0;
  piva = null;
  titolo = null;
  ruolo = null;
  nome = null;
  cognome = null;
  telefono = null;
  cellulare = null;
  presente_whatsapp = false;
  disabilitato = false;
  email = null;
  created = undefined;
  updated = undefined;
}
