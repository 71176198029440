import { ActionType } from '../../..';
import { fetchAnagraficaTipologie, fetchAnagraficaCategorie } from '../actions';

export const AnagraficheTipologieMapAction: Record<ActionType | string, any> = {
  fetchList: fetchAnagraficaTipologie,
};

export const AnagraficheCategorieMapAction: Record<ActionType | string, any> = {
  fetchList: fetchAnagraficaCategorie,
};
