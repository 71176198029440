import { createSelector } from 'reselect';
import { RootState } from '../../../applicationStore';
import { IDLE, PENDING, FAILED, SUCCEEDED } from '../../../types';

export const getAddressState = (state: RootState) => state.cap;

export const getAddressLoader = createSelector(
  getAddressState,
  (addressState) => addressState.loading === PENDING
);

export const getAddressFailed = createSelector(
  getAddressState,
  (addressState) => addressState.loading === FAILED
);

export const getAddressSuccess = createSelector(
  getAddressState,
  (addressState) => addressState.loading === SUCCEEDED
);

export const getAddressData = createSelector(
  getAddressState,
  (addressState) => addressState?.data ?? []
);

export const getAdderssPresentation = createSelector(getAddressData, (addressData) =>
  addressData?.slice(0, 100)?.map((element, index) => ({
    id: index,
    description: `${element.cap}, ${element.citta} (${element.sigla_provincia})`,
  }))
);
