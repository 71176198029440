import { createSlice } from '@reduxjs/toolkit';
import { FAILED, IDLE, PENDING, State, SUCCEEDED } from '../../..';
import { merge } from '../../../../utils';
import { ModuloChiave } from '../../moduloChiave/types/model';
import {
  fetchModuloChiaviOptions,
  fetchModuloChiavi,
  setModuloChiaviParams,
  resetModuloChiaviParams,
  setModuloChiaviParamsOption,
  resetModuloChiaviParamsOption,
  setModuloChiaviSelectedIds,
  resetModuloChiaviSelectedIds,
} from '../actions';

import { resetModuloChiavi } from '../actions/reset';
import { resetModuloChiaviOption } from '../actions/resetOption';
import { selectModuloChiaviAll } from '../actions/selectAll';
import { updateSingleRecordModuloChiavi } from '../actions/updateSingleRecord';

const initialState: State<ModuloChiave> = {
  data: [],
  info: undefined,
  dataOption: [],
  infoOption: undefined,
  selectedIds: [],
  params: undefined,
  paramsOption: undefined,
  error: null,
};

export const moduloChiaviSlice = createSlice({
  name: 'moduloChiavi',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchModuloChiavi.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.data = data?.list;
        state.info = data?.info;
        state.loading = SUCCEEDED;
        state.error = null;
      })
      .addCase(fetchModuloChiavi.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(fetchModuloChiavi.rejected, (state, action) => {
        // const statusCode = payload?.statusCode ?? 500;
        // const error = payload?.error;
        const payload = action?.error;
        const statusCode = payload?.code ?? payload?.name;
        state.error = payload?.message ?? payload?.name ?? statusCode;
        state.loadingOption = FAILED;
      })

      .addCase(selectModuloChiaviAll.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.selectedIds = data;
        state.loading = SUCCEEDED;
        state.error = null;
      })
      .addCase(selectModuloChiaviAll.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(selectModuloChiaviAll.rejected, (state, action) => {
        const payload = action?.error;
        const statusCode = payload?.code ?? payload?.name;
        state.error = payload?.message ?? payload?.name ?? statusCode;
        state.loadingOption = FAILED;
      })

      .addCase(fetchModuloChiaviOptions.fulfilled, (state, { payload }) => {
        state.dataOption = payload?.data;
        state.loadingOption = SUCCEEDED;
        state.error = null;
      })
      .addCase(fetchModuloChiaviOptions.pending, (state) => {
        state.loadingOption = PENDING;
      })
      .addCase(fetchModuloChiaviOptions.rejected, (state, action) => {
        const payload = action?.error;
        const statusCode = payload?.code ?? payload?.name;
        state.error = payload?.message ?? payload?.name ?? statusCode;
        state.loadingOption = FAILED;
      })
      .addCase(resetModuloChiavi, (state) => {
        state.data = [];
        state.info = undefined;
        state.loading = IDLE;
        state.params = undefined;
        state.selectedIds = [];
      })
      .addCase(resetModuloChiaviOption, (state) => {
        state.dataOption = [];
        state.infoOption = undefined;
        state.loadingOption = IDLE;
        state.paramsOption = undefined;
      })
      .addCase(setModuloChiaviParams, (state, { payload }) => {
        state.params = { ...state.params, ...payload };
      })
      .addCase(resetModuloChiaviParams, (state) => {
        state.params = undefined;
      })
      .addCase(setModuloChiaviParamsOption, (state, { payload }) => {
        state.paramsOption = { ...state.paramsOption, ...payload };
      })
      .addCase(resetModuloChiaviParamsOption, (state) => {
        state.paramsOption = undefined;
      })
      .addCase(setModuloChiaviSelectedIds, (state, { payload }) => {
        const ids = state.selectedIds;
        state.selectedIds = ids.includes(payload)
          ? ids.filter((currentId: string | number) => currentId !== payload)
          : [payload, ...state.selectedIds];
      })
      .addCase(resetModuloChiaviSelectedIds, (state) => {
        state.selectedIds = [];
      })
      .addCase(updateSingleRecordModuloChiavi, (state, { payload }) => {
        if (state.data) {
          state.data = state.data?.map((row) =>
            Number(row.id) === Number(payload.id) ? payload : row
          );
        }
        if (state.dataOption) {
          state.dataOption = state.dataOption?.map((row) =>
            Number(row.id) === Number(payload.id) ? payload : row
          );
        }
      });
  },
});
