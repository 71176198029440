import {
  CODICE_FISCALE_REGEX,
  PARTITA_IVA_REGEX,
  PHONE_NUMBER,
  validateCodiceFiscale,
  validatePiva,
} from 'utils/constants/validations';
import * as Yup from 'yup';

const anagraficaSchema = Yup.object().shape({
  ragione_sociale: Yup.string().nullable(true).required('campo obbligatorio'),
  piva: Yup.string()
    .required('campo obbligatorio')
    .nullable(true)
    .test('piva', 'P.IVA non valida', (value) => {
      return Promise.resolve(validatePiva(value));
    }),
  codice_fiscale: Yup.string()
    .nullable(true)
    .test('piva', 'Codice Fiscale non valido', (value) => {
      return Promise.resolve(validateCodiceFiscale(value));
    }),
  pec: Yup.string().email('inserire una pec valida').nullable(true),
  email: Yup.string().email('inserire una e-mail valida').nullable(true),
  cellulare: Yup.string()
    .nullable(true)
    .test('cellulare', 'Numero cellulare non valido', (value) => {
      return Promise.resolve(value ? PHONE_NUMBER.test(value) : true);
    }),
  telefono: Yup.string()
    .nullable(true)
    .test('telefono', 'Numero telefono fisso non valido', (value) => {
      return Promise.resolve(value ? PHONE_NUMBER.test(value) : true);
    }),
});

export default anagraficaSchema;
