import { selectorTypes } from '../../..';
import {
  getSchede,
  getSchedeFailed,
  getSchedeInfo,
  getSchedeLoader,
  getSchedeOption,
  getSchedeOptionFailed,
  getSchedeOptionInfo,
  getSchedeOptionLoader,
  getSchedeOptionSuccess,
  getSchedeParams,
  getSchedeParamsOption,
  getSchedeSelectedIds,
  getSchedeSuccess,
  getSchedaById,
  getSchedaOptionById,
  getSchedeKeyValues,
  getSchedeOptionKeyValues,
} from '../selectors';

export const SchedeMapSelector = {
  loading: getSchedeLoader,
  success: getSchedeSuccess,
  failed: getSchedeFailed,
  data: getSchede,
  info: getSchedeInfo,
  params: getSchedeParams,
  loadingOption: getSchedeOptionLoader,
  successOption: getSchedeOptionSuccess,
  failedOption: getSchedeOptionFailed,
  dataOption: getSchedeOption,
  infoOption: getSchedeOptionInfo,
  paramsOption: getSchedeParamsOption,
  selectedIds: getSchedeSelectedIds,
  selectKeyValues: getSchedeKeyValues,
  selectOptionKeyValues: getSchedeOptionKeyValues,
  elementById: getSchedaById,
  elementOptionById: getSchedaOptionById,
};

export default SchedeMapSelector;
