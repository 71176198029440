import { selectorTypes } from '../../..';
import {
  getModuli,
  getModuliFailed,
  getModuliInfo,
  getModuliKeyValues,
  getModuliLoader,
  getModuliOption,
  getModuliOptionFailed,
  getModuliOptionInfo,
  getModuliOptionKeyValues,
  getModuliOptionLoader,
  getModuliOptionSuccess,
  getModuliParams,
  getModuliParamsOption,
  getModuliSelectedIds,
  getModuliSuccess,
  getModuloById,
  getModuloOptionById,
} from '../selectors';

export const ModuliMapSelector = {
  loading: getModuliLoader,
  success: getModuliSuccess,
  failed: getModuliFailed,
  data: getModuli,
  info: getModuliInfo,
  params: getModuliParams,
  loadingOption: getModuliOptionLoader,
  successOption: getModuliOptionSuccess,
  failedOption: getModuliOptionFailed,
  dataOption: getModuliOption,
  infoOption: getModuliOptionInfo,
  paramsOption: getModuliParamsOption,
  selectedIds: getModuliSelectedIds,
  selectKeyValues: getModuliKeyValues,
  selectOptionKeyValues: getModuliOptionKeyValues,
  elementById: getModuloById,
  elementOptionById: getModuloOptionById,
};

export default ModuliMapSelector;
