import { createSlice } from '@reduxjs/toolkit';
import { postSignIn, getRefreshToken, getLogout, registerUser } from '../actions';
import Cookies from 'js-cookie';
import { confirmUser } from '../actions/confirmUser';
import { IDLE, PENDING, FAILED, SUCCEEDED } from '../../types';
import { AuthResponse, RegisterUserResponse } from '..';
import { AuthState } from '../types/state';

const token = Cookies.get('token') || null;
const refreshToken = Cookies.get('refreshToken') || null;

const initialAuthData: AuthResponse = {
  statusCode: null,
  isAuthenticated: !!token,
  token: token,
  refreshToken: refreshToken,
  errors: null,
};
const registerData: RegisterUserResponse = {
  statusCode: null,
  email: null,
};

const initialState: AuthState = {
  data: initialAuthData,
  registerData,
  loading: IDLE,
  error: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(confirmUser.fulfilled, (state, { payload }) => {
        const statusCode = payload?.statusCode;
        const data = payload?.data;
        state.data = {
          statusCode: statusCode,
          isAuthenticated: true,
          refreshToken: data?.refresh_token,
          token: data?.token,
          errors: [],
        };
        state.loading = SUCCEEDED;
      })
      .addCase(confirmUser.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(confirmUser.rejected, (state, action) => {
        state.error = action?.payload || '';
        state.loading = FAILED;
      })
      .addCase(postSignIn.fulfilled, (state, { payload }) => {
        const statusCode = payload?.statusCode;
        const data = payload?.data;
        state.data = {
          statusCode: statusCode,
          isAuthenticated: true,
          refreshToken: data?.refresh_token,
          token: data?.token,
          errors: [],
        };
        state.loading = SUCCEEDED;
      })
      .addCase(postSignIn.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(postSignIn.rejected, (state, action) => {
        state.error = action?.payload || '';
        state.loading = FAILED;
      })
      .addCase(getRefreshToken.fulfilled, (state, { payload }) => {
        const statusCode = payload?.statusCode;
        const data = payload?.data;
        state.data = {
          statusCode: statusCode,
          isAuthenticated: true,
          refreshToken: data?.refresh_token,
          token: data?.token,
          errors: [],
        };
        state.loading = SUCCEEDED;
      })
      .addCase(getRefreshToken.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(getRefreshToken.rejected, (state, action) => {
        state.error = action?.error?.code || action?.error?.name || '';
        state.loading = FAILED;
      })
      .addCase(getLogout.fulfilled, (state, { payload }) => {
        state.data = null;
        state.loading = SUCCEEDED;
      })
      .addCase(getLogout.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(getLogout.rejected, (state, action) => {
        state.data = null;
        state.error = action?.error?.code || action?.error?.name || '';
        state.loading = FAILED;
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        const statusCode = payload?.statusCode;
        const data = payload?.data;
        state.loading = SUCCEEDED;
        const registerData: RegisterUserResponse = {
          statusCode: statusCode,
          email: data.email,
        };
        state.registerData = registerData;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.registerData = null;
        state.error = action?.error?.code || action?.error?.name || '';
        state.loading = FAILED;
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = PENDING;
      });
  },
});
