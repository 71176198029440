import { createSelector } from 'reselect';
import { FAILED, PENDING, RootState, SUCCEEDED } from '../../../..';

export const getReferenteState = (state: RootState) => state.referente;

export const getReferenteLoader = createSelector(
  getReferenteState,
  (referenteState) => referenteState.loading === PENDING
);

export const getReferenteFailed = createSelector(
  getReferenteState,
  (referenteState) => referenteState.loading === FAILED
);

export const getReferenteSuccess = createSelector(
  getReferenteState,
  (referenteState) => referenteState.loading === SUCCEEDED
);

export const getReferenteError = createSelector(
  getReferenteState,
  (referenteState) => referenteState?.error
);

export const getReferente = createSelector(
  getReferenteState,
  (referenteState) => referenteState?.data
);
