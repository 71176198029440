type JsonKeyValue = Record<string, any>;

export const keyify = (obj: JsonKeyValue, prefix = ''): string[] =>
  Object.keys(obj).reduce((res, el): any => {
    if (Array.isArray(obj[el])) {
      return [...res, prefix + el];
    } else if (typeof obj[el] === 'object' && obj[el] !== null) {
      return [...res, ...keyify(obj[el], prefix + el + '.')];
    }
    return [...res, prefix + el];
  }, []);

export const removeVoidStringFromObject = (object: JsonKeyValue): JsonKeyValue =>
  keyify(object).reduce((accumulator: JsonKeyValue, key: string) => {
    const value = findNestedProp(object, key);
    accumulator[key] = value === '' ? null : value;
    return accumulator;
  }, {});

export const findNestedProp = (
  obj: JsonKeyValue,
  key: string,
  defaultValue?: string | number | boolean | null
): any => {
  if (typeof defaultValue === 'undefined') defaultValue = null;
  const propSplit = key.split('.');
  for (let i = 0; i < propSplit.length; i++) {
    if (typeof obj[propSplit[i]] === 'undefined') return defaultValue;
    obj = obj[propSplit[i]];
  }
  return obj;
};

export const trimStringFromObject = (object: JsonKeyValue): JsonKeyValue => {
  const keys = keyify(object);
  const obj = JSON.parse(JSON.stringify(object));
  keys.forEach((key: string) => {
    fullTrimProperty(key, obj);
  });
  return obj;
};

export const fullTrimProperty = (path: string, obj: JsonKeyValue): void => {
  let schema = obj;
  const pList = path.split('.');
  const len = pList.length;
  for (let i = 0; i < len - 1; i++) {
    const elem = pList[i];
    if (!schema[elem]) schema[elem] = {};
    schema = schema[elem];
  }

  if (typeof schema[pList[len - 1]] === 'string') {
    schema[pList[len - 1]] = (schema[pList[len - 1]] as string).replace(/\s\s+/g, ' ').trim();
  }
};

export const removeEmpty = (obj: JsonKeyValue): JsonKeyValue => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v !== null || v !== undefined)
      .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
  );
};

export const removeUndefinedAndNullValues = (obj: JsonKeyValue): void => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined || obj[key] === null) {
      delete obj[key];
    }
    if (typeof obj[key] === 'object') {
      removeUndefinedAndNullValues(obj[key]);
    }
  });
};

export const returnObjectsWithExistingField = (
  object: Record<string, any>,
  fieldToSearch: string,
  findings: any[] = []
): any => {
  const nestedField = getNestedUnknownFieldByPath(object, fieldToSearch);
  if (nestedField) {
    return object;
  }

  Object.keys(object).forEach((key) => {
    if (typeof object[key] === 'object') {
      const newObj = returnObjectsWithExistingField(object[key], fieldToSearch, findings);
      if (getNestedUnknownFieldByPath(newObj, fieldToSearch)) {
        findings.push(newObj);
      }
    }
  });

  return findings;
};

export const getNestedUnknownFieldByPath = (object: Record<string, any>, path: string): Error =>
  path.split('.').reduce((accumulator, currentValue, currentIndex) => {
    // @ts-ignore
    if (accumulator && currentValue in accumulator) {
      return accumulator[currentValue];
    } else {
      if (currentIndex === path.split('.').length) {
        return accumulator;
      } else {
        return null;
      }
    }
  }, object as unknown as any);
