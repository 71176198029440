import React, {
  DOMAttributes,
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  ReactNode,
} from 'react';
interface BoxProps extends React.CSSProperties, DOMAttributes<HTMLDivElement> {
  children?: ReactNode;
  className?: string;
}
export const Box = forwardRef(
  ({ children, ...rest }: PropsWithChildren<BoxProps>, ref?: ForwardedRef<HTMLDivElement>) => {
    const style: React.CSSProperties = rest;
    const elementAttributes: any = rest;
    type DomAttributesKeys = keyof DOMAttributes<HTMLDivElement>;
    const attrs = Object.keys(elementAttributes).reduce((accumulator: any, key: string) => {
      try {
        if (typeof elementAttributes[key] === 'function') {
          accumulator[key as DomAttributesKeys] = elementAttributes[key];
        }
        return accumulator;
      } catch (error) {
        console.log({ error });
      }
    }, {} as DOMAttributes<HTMLDivElement>);
    return (
      <div ref={ref} style={{ ...style }} {...attrs}>
        {children}
      </div>
    );
  }
);

export default Box;
