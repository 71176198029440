import * as Yup from 'yup';

const schedaSchema = Yup.object().shape({
  nome: Yup.string().nullable().required('campo obbligatorio'),
  mese: Yup.string().nullable().required('campo obbligatorio'),
  anno: Yup.string().nullable().required('campo obbligatorio'),
  modulo: Yup.object()
    .nullable()
    .shape({
      id: Yup.number().nullable().required(),
    })
    .required('campo obbligatorio'),
});

export default schedaSchema;
