import { ActionType } from '../../..';
import {
  createModuloChiave,
  deleteModuloChiave,
  fetchModuloChiave,
  resetModuloChiave,
} from '../actions';

export const ModuloChiaveMapAction: Record<ActionType | string, any> = {
  fetch: fetchModuloChiave,
  submit: createModuloChiave,
  delete: deleteModuloChiave,
  reset: resetModuloChiave,
};
