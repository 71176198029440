import { SelectorType } from '../../../utils/constants/selectorTypes';
import { getScheda, getSchedaFailed, getSchedaLoader, getSchedaSuccess } from '../selectors';

export const SchedaMapSelector = {
  loadingElement: getSchedaLoader,
  successElement: getSchedaSuccess,
  failedElement: getSchedaFailed,
  element: getScheda,
};

export default SchedaMapSelector;
