import { ActionType, UPDATE_SINGLE_RECORD } from '../../..';
import {
  fetchFiltri,
  fetchFiltriOptions,
  resetFiltroParams,
  resetFiltroParamsOption,
  resetFiltroSelectedIds,
  resetFiltri,
  resetFiltriOption,
  setFiltroParams,
  setFiltroParamsOption,
  setFiltroSelectedIds,
  selectFiltroAll,
  deleteFiltriMulti,
  updateSingleRecordFiltri,
} from '../actions';

export const FiltriMapAction: Record<ActionType | string, any> = {
  fetchList: fetchFiltri,
  fetchListOption: fetchFiltriOptions,
  setParams: setFiltroParams,
  setParamsOption: setFiltroParamsOption,
  setSelectedIds: setFiltroSelectedIds,
  resetSelectedIds: resetFiltroSelectedIds,
  resetParams: resetFiltroParams,
  resetParamsOption: resetFiltroParamsOption,
  resetList: resetFiltri,
  resetListOption: resetFiltriOption,
  setSelectAll: selectFiltroAll,
  deleteMulti: deleteFiltriMulti,
  updateSingleRecord: updateSingleRecordFiltri,
};
