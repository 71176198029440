import { useSelector } from 'react-redux';
import { EnumsId, selectorTypes } from '..';
import { AnagraficheCategorieEnumSelector, AnagraficheTipolpgieEnumSelector } from '../anagrafiche';

const mapEnumSelector: Record<string, any> = {
  ANAGRAFICA_CATEGORIA: AnagraficheCategorieEnumSelector,
  ANAGRAFICA_TIPOLOGIA: AnagraficheTipolpgieEnumSelector,
};

export const getSelectorEnumLoading = (enumId: string): any =>
  useSelector(mapEnumSelector[enumId][selectorTypes.loading]);
export const getSelectorEnumFailed = (enumId: string): any =>
  useSelector(mapEnumSelector[enumId][selectorTypes.failed]);
export const getSelectorEnumSuccess = (enumId: string): any =>
  useSelector(mapEnumSelector[enumId][selectorTypes.success]);
export const getSelectorEnumData = (enumId: string): any =>
  useSelector(mapEnumSelector[enumId][selectorTypes.data]);
export const getSelectorEnumElement = (enumId: string, id: string | number): any =>
  useSelector(mapEnumSelector[enumId][selectorTypes.elementById](id));
