import FormInput from 'components/FormInput';
import SimpleTable from 'components/SimpleTable';
import { Card, Row, Col } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getFiltroChiavi } from 'store';

export const ElencoFiltri = () => {
  const header = [
    {
      key: 'id',
      value: 'id',
    },
    {
      key: 'chiave',
      value: 'Colonna filtro',
    },
    {
      key: 'valore',
      value: 'Valore',
    },
    {
      key: 'created',
      value: 'Data Creazione',
    },
  ];

  const rows = useSelector(getFiltroChiavi());

  return (
    <Card className="mb-3">
      <Card.Header>
        <span>Elenco campi filtri</span>
      </Card.Header>
      <Card.Body>
        <Row>
          <SimpleTable header={header} rows={rows} templateRow="auto" />
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ElencoFiltri;
