import { selectorTypes } from '../../../..';
import {
  getReferenti,
  getReferentiFailed,
  getReferentiInfo,
  getReferentiLoader,
  getReferentiOption,
  getReferentiOptionFailed,
  getReferentiOptionInfo,
  getReferentiOptionLoader,
  getReferentiOptionSuccess,
  getReferentiParams,
  getReferentiParamsOption,
  getReferentiSelectedIds,
  getReferentiSuccess,
  getReferenteById,
  getReferenteOptionById,
  getReferentiKeyValues,
  getReferentiOptionKeyValues,
} from '../selectors';

export const ReferentiMapSelector = {
  loading: getReferentiLoader,
  success: getReferentiSuccess,
  failed: getReferentiFailed,
  data: getReferenti,
  info: getReferentiInfo,
  params: getReferentiParams,
  loadingOption: getReferentiOptionLoader,
  successOption: getReferentiOptionSuccess,
  failedOption: getReferentiOptionFailed,
  dataOption: getReferentiOption,
  infoOption: getReferentiOptionInfo,
  paramsOption: getReferentiParamsOption,
  selectedIds: getReferentiSelectedIds,
  selectKeyValues: getReferentiKeyValues,
  selectOptionKeyValues: getReferentiOptionKeyValues,
  elementById: getReferenteById,
  elementOptionById: getReferenteOptionById,
};

export default ReferentiMapSelector;
