import { useState, useEffect } from 'react';

interface DebounceProps {
  searchTerm: string;
  delay: number;
}

export default function useDebounce({ searchTerm, delay }: DebounceProps) {
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  return debouncedSearchTerm;
}
