import { createSelector } from 'reselect';
import { IDLE, PENDING, FAILED, SUCCEEDED, RootState } from '../../..';

export const getCittaState = (state: RootState) => state.citta;

export const getCittaLoader = createSelector(
  getCittaState,
  (cittaState) => cittaState.loading === PENDING
);

export const getCittaFailed = createSelector(
  getCittaState,
  (cittaState) => cittaState.loading === FAILED
);

export const getCittaSuccess = createSelector(
  getCittaState,
  (cittaState) => cittaState.loading === SUCCEEDED
);

export const getCittaError = createSelector(getCittaState, (cittaState) => cittaState?.error);

export const getCitta = createSelector(getCittaState, (cittaState) => cittaState?.data);
